import { Injectable } from '@angular/core';
import { MixpanelCategory } from '../../interfaces/mixpanel-category.enum';
import { MixpanelData } from '../../interfaces/mixpanel-data';
import { MixpanelEvent } from '../../interfaces/mixpanel-events.enum';
import * as mixpanel from 'mixpanel-browser';
import { StorageService } from '../storage.service';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';


@Injectable()
export class MixpanelService {
  env: any;
  private mixpanelToken: string;
  private people = new BehaviorSubject<string>(null);
  cancelText = 'El cliente cancela y retrocede';
  generalPeopleId = "";

  /**
   * constructor
   * get mixpanel token and initialize
   */
  constructor(
    private envParams: EnvironmentParamsService,
    private storage: StorageService
  ) {
    this.env = this.envParams.getEnvironment() as { prd: boolean, rtu: string, comm: string };
    this.mixpanelToken = this.env.prd? 'e9cae6b154150a92545d081420ae20ed':'e9e5013919fdb9f093775672b89783aa';
  }

  /**
   * Initialize mixpanel.
   */
  init() {
    mixpanel.init(this.mixpanelToken, { debug: this.env.prd ? false : true });
   console.log('mixpanel:', this.mixpanelToken);
  }
  /**
    * Create new Alias for user
    * Call this method only once in user lifecycle
    *
    * @param {string} alias
    */
  createAlias(alias: string) {
    mixpanel.alias(alias, mixpanel.get_distinct_id());
  }

  /**
   * Identify User
   *
   * @param {string} alias
   */
  identify(alias: string) {
    mixpanel.identify(alias);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */


  track(event: MixpanelEvent, parameters: any = {}) {

    const eventData: MixpanelData = this.getEventInfo(event);

    parameters.Seller = this.env.rtu;
    parameters.BankID = this.getBankId(this.env.comm);
    parameters.FlowId = this.getFlowId();
    parameters.Commerce = 'Tienda web';
    parameters.IpadDate = new Date().toString();
    parameters.IpadTimestamp = new Date().getTime();
    parameters.Message = eventData.message;
    parameters.Category = eventData.category;
    parameters.Identified = eventData.identified;

    mixpanel.track(event, parameters);
  }

  private getEventInfo(event: MixpanelEvent): MixpanelData {
    const data: MixpanelData[] = this.data();
    return data.find(
      (mixpanelData: MixpanelData) => mixpanelData.event === event
    );
  }

  private data(): MixpanelData[] {
    return [
      ...this.initEvents(),
      ...this.loginEvents(),
      ...this.menuEvents(),
      ...this.disclaimerEvents(),
      ...this.fingerprintEvents(),
      ...this.facialEvents(),
      ...this.clientEvents(),
      ...this.dataEvents(),
      ...this.smsEvents(),
      ...this.offerEvents(),
      ...this.contractEvents(),
      ...this.curseEvents(),
      ...this.simulateEvents(),
      ...this.paysmsEvents(),
      ...this.buyEvents(),
      ...this.cancelEvents(),
      ...this.chashboxEvents()
    ];
  }

  /**
   * setup mixpannel
   *
   */
  setup() {
    //mixpanel.loggingEnabled = false;
    this.setSuperProperties({ Platform: 'web' });
  }

  /**
    * setPeople
    * Store user profiles in Mixpanel's People Analytics product
    * @param {Object} properties
    */
  setPeople(properties: any = {}) {
    mixpanel.people.set(properties);
  }

  /**
   * setSuperProperties
   *
   * @param {object} properties
   */
  setSuperProperties(properties) {
    mixpanel.register(properties);
  }

  /**
   * sendEvent
   *
   * @param {string} event
   * @param {object} properties
   */
  sendEvent(event: string, properties?) {
    if (properties) {
      mixpanel.track(event, properties);
    } else {
      this.trackEvent(event);
    }
  }

  /**
   * trackEvent
   * @param {string} event
   */
  trackEvent(event: string) {
    mixpanel.track(event);
  }

  /**
   * Reset Mixpanel
   */
  logout() {
    mixpanel.reset();
  }

  /****************/

  createFlowId() {
    const code = this.makeId(20);
    this.storage.setItem('flowId', code);
  }

  getFlowId(): string {
    return this.storage.getItem('flowId') || '';
  }

  getPeopleId(): string {
    return this.people.getValue();
  }

  updatePeopleId(id: string) {
    this.people.next(id);
  }

  createPerson(identified: string = null) {
    let peopleId: string = this.makeId(15, true);
    if (identified) {
      peopleId = identified;
    }
    mixpanel.identify(peopleId);
    this.setPeople({
      name: peopleId,
      id: peopleId,
      identified: 's',
      init: new Date().toLocaleString('es-CL', { timeZone: 'America/Santiago' })
    });
    this.updatePeopleId(peopleId);
    this.generalPeopleId = peopleId;
  }

  deleteUser() {
    mixpanel.people.delete_user();
  }

  async deletePerson() {
    await mixpanel.reset();
    await mixpanel.identify(this.generalPeopleId);
    await this.deleteUser();
    this.updatePeopleId(null);
    this.createFlowId();
  }

  /* */
  private makeId(length: number, uppercase: boolean = false): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return uppercase ? result.toUpperCase() : result;
  }

  private initEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.INIT_INIT,
        message: 'El cliente inicia la aplicación',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.INIT_NEXT,
        message: 'El cliente continua al login',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.INIT_ERR,
        message: 'No se puede validar el comercio WEB',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.INIT_ACCEPT_DISC,
        message: 'El cliente acepta el disclaimer',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.INIT_SHOW_REQ,
        message: 'El cliente visualiza los requerimientos WEB',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.INIT_BROWSER_ERR,
        message: 'El cliente no tiene un navegador compatible con el flujo TOC',
        category: MixpanelCategory.USER,
        identified: 'No'
      }
    ];
  }

  private loginEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.LOGIN_INIT,
        message: 'El cliente ingresa al formulario de login',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.LOGIN_NEXT,
        message: 'El cliente ingresa las credenciales y continua',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.LOGIN_ERR_CRED,
        message: 'El cliente ingresa las credenciales incorrectamente',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.LOGIN_ERR_CONN,
        message: 'No hay conexión a internet',
        category: MixpanelCategory.APP,
        identified: 'No'
      },
      {
        event: MixpanelEvent.LOGIN_ERR_RESP,
        message: 'No hay respuesta desde la api',
        category: MixpanelCategory.API,
        identified: 'No'
      },//--
      {
        event: MixpanelEvent.VALIDATECI_INIT,
        message: 'El cliente inicia proceso de validacion CI',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_API,
        message: 'Se inicia API ValidateCI',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_API_ERR,
        message: 'Error en la API ValidaCI',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_RECAPTCHA,
        message: 'Se inicia API ValidateCI (Recaptcha)',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_RECAPTCHA_ERR,
        message: 'Error en la API ValidaCI (Recaptcha)',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_NEXT,
        message: 'El cliente se valida su CI y va a las instrucciones',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_NEXT_TOC,
        message: 'El cliente presiona aceptar y va a la validacion facial',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_VALIDA_CLIENTE,
        message: 'El cliente se valida previamente',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_VALIDA_CLIENTE_OK,
        message: 'Consulta cliente en etapa identificación ok',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_VALIDA_CLIENTE_KO,
        message: 'Consulta cliente en etapa identificación rechazado',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_VALIDA_CLIENTE_ERR,
        message: 'Error en consulta cliente en identificación',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_INGRESO_CI,
        message: 'ingreso CI',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_INGRESO_SERIE,
        message: 'Ingreso serie',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.VALIDATECI_VALIDA_GIF,
        message: 'El cliente presiona el botón COMENZAR en la pantalla de GIF',
        category: MixpanelCategory.USER,
        identified: 'No'
      }
    ];
  }

  private menuEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.MENU_EXIT,
        message: 'El cliente cierra sesión',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.MENU_INIT,
        message: 'El cliente entra al menu principal',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.MENU_REQUEST,
        message: 'El cliente entra a solicitud de TCV',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.MENU_PAY,
        message: 'El cliente entra a pago',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.MENU_CANCEL,
        message: 'El cliente entra a anulación',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.MENU_RESUME,
        message: 'El cliente entra a caja',
        category: MixpanelCategory.USER,
        identified: 'No'
      }
    ];
  }

  private disclaimerEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.DISCLAIMER_EXIT,
        message: 'El cliente abandona la autorización',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.DISCLAIMER_INIT,
        message: 'El cliente visualiza la autorización',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.DISCLAIMER_TYC,
        message: 'El cliente hace click en el check tyc',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.DISCLAIMER_ACCEPT,
        message: 'El cliente acepta la autorización',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.DISCLAIMER_CANCEL,
        message: 'El cliente rechaza la autorización',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.DISCLAIMER_UPLOAD,
        message: 'Se sube el disclaimer ',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DISCLAIMER_UPLOAD_ERR,
        message: 'Error al subir el disclaimer',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.INIT_CANCEL_DISC,
        message: 'El cliente no acepta disclaimer WEB',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.INIT_NEXT_WEB,
        message: 'El cliente acepta comenzar el flujo de identificación WEB',
        category: MixpanelCategory.USER,
        identified: 'Si'
      }
    ];
  }

  private fingerprintEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.FINGERPRINT_EXIT,
        message: 'El cliente abandona validación dactilar',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FINGERPRINT_INIT,
        message: 'Se inicializa el proceso de validacion dactilar',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FINGERPRINT_TOC_INIT,
        message: 'Se inicia la app de toc',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FINGERPRINT_TOC_RESPONSE,
        message: 'La app de toc llama a la app del santander',
        category: MixpanelCategory.APP,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FINGERPRINT_TOC_RESULT,
        message: 'Se valida el resultado de la app de toc',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FINGERPRINT_NEXT,
        message: 'El cliente es validado y continua al flujo siguiente',
        category: MixpanelCategory.USER,
        identified: 'No'
      }
    ];
  }

  private facialEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.FACIAL_EXIT,
        message: 'El cliente abandona validación facial',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_INIT,
        message: 'Se inicializa la validación facial',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_NEXT_TAKE,
        message: 'El cliente continua a la toma de imagenes',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_EXIT,
        message: 'El cliente abandona la toma de imagenes',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_INIT,
        message: 'El cliente inicia el proceso de toma de imagenes',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_SELFIE,
        message: 'Se captura la imagen 1 de rostro',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_DOC_A,
        message: 'Se captura la imagen 2 del carnet frontal',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_DOC_B,
        message: 'Se captura la imagen 3 del carnet reverso',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_NEXT,
        message: 'El cliente es validado y continua al flujo siguiente',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_NEXT_ERR,
        message: 'Error en la validación facial',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_SM_INIT,
        message: 'Inicio token session manager',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_SM_ERR,
        message: 'Error en token session manager facial',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_SELFIE_OK,
        message: 'Movimiento cara ok',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_SELFIE_ERR,
        message: 'Error en movimiento cara',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_DOC_A_OK,
        message: 'Se captura la imagen 02 del carnet frontal resultado OK',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_DOC_A_ERR,
        message: 'Se captura la imagen 02 del carnet frontal resultado error',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_DOC_B_OK,
        message: 'Se captura la imagen 03 del carnet reverso resultado OK',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_TAKE_DOC_B_ERR,
        message: 'Se captura la imagen 03 del carnet reverso resultado error',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.FACIAL_NEXT_OK,
        message: 'El cliente es validado y continua al flujo siguiente (ejecura api FacilaCI con OK)',
        category: MixpanelCategory.USER,
        identified: 'No'
      }
    ];
  }

  private clientEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.CLIENT_INIT,
        message: 'El cliente inicia proceos de etapa de captura',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_FIND,
        message: 'Se consulta el estado del cliente',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_GET,
        message: 'Se capturan los datos del cliente de servicios externos',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_RESUME,
        message:
          'El cliente ya inicio el proceso de solicitud y se recupera el estado',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CLIENT_ERR,
        message: 'Error en la captura de cliente',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_REGISTER,
        message: 'Se da de alta al cliente',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CLIENT_REGISTER_ERR,
        message: 'Error al dar de alta al cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CLIENT_GET_QUESTIONS_INIT,
        message: 'El cliente inicia las preguntas de equifax',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_GET_QUESTIONS_ERR,
        message: 'Error al generar preguntas equifax',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_GET_ANSWER_INIT,
        message: 'El cliente inicia las respuestas de equifax',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_GET_ANSWER_ERR,
        message: 'Error al responder preguntas equifax',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.CLIENT_RESUME_ERR,
        message: 'Es un estado que no se puede retomar (estado 0)',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CLIENT_FIND_ERR,
        message: 'Se consulta el estado del cliente y termina con error (viene de 07-01)',
        category: MixpanelCategory.API,
        identified: 'No'
      }
    ];
  }

  private dataEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.DATA_EXIT,
        message: 'El cliente abandona la actualiación de datos',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_INIT,
        message: 'El cliente inicia el proceso de ingreso de informacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_MARRIED,
        message: 'Estado civil del cliente',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_REGION,
        message: 'El cliente abre las opciones de regiones',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_REGION_SELECTED,
        message: 'El cliente selecciona la region',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_COMUNA,
        message: 'El cliente abre las opciones de comuna',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_COMUNA_SELECTED,
        message: 'El cliente selecciona una comuna',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_CITY,
        message: 'El cliente abre las opciones de ciudad',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_CITY_SELECTED,
        message: 'El cliente selecciona una ciudad',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_STUDY,
        message: 'El cliente abre las opciones de nivel de estudio',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_STUDY_SELECTED,
        message: 'El cliente selecciona el nivel de estudio',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_OTHER_ACTIVITY,
        message: 'El cliente selecciona otra actividad',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_OTHER_ACTIVITY_CHECK,
        message: 'El cliente selecciona el check otra actividad',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_NEXT,
        message: 'El cliente completa toda la información requerida y continua',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_SAVE,
        message: 'El cliente completa toda la información requerida y continua',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_SAVE_ERROR,
        message: 'Error al guardar la información del cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.DATA_COUNTRY,
        message: 'El cliente selecciona nacionalidad',
        category: MixpanelCategory.USER,
        identified: 'Si'
      }
    ];
  }

  private smsEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.SMS_EXIT,
        message: 'El cliente abandona la validación sms',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_INIT,
        message: 'Se inicia la solicitud del sms',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_SEND,
        message: 'Se envía un SMS al cliente',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_SEND_ERR,
        message: 'Ocurrió un error enviando el SMS',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_WAIT,
        message: 'Se inicia la espera de ingreso código SMS',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_RESEND,
        message: 'Se solicita reenviar el código SMS',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_ENTER,
        message: 'El cliente recibe el código e ingresa',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_VALIDATE_ERR,
        message: 'Ocurrió un error validando el SMS',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SMS_NEXT,
        message: 'El cliente continua',
        category: MixpanelCategory.USER,
        identified: 'Si'
      }
    ];
  }

  private offerEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.OFFER_INIT,
        message: 'El cliente inicia el proceso de selección de oferta',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC,
        message: 'Cantidad de tarjetas ofrecidas al cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_ERR,
        message: 'Error al obtener las tarjetas de crédito',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_AMOUNT_ACCEPT,
        message: 'El cliente no tiene cupo suficiente para realizar la compra',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_AMOUNT_CANCEL,
        message: 'El cliente no tiene cupo suficiente para realizar la compra',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_SELECTED,
        message: 'El cliente selecciona una tarjeta de crédito',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_ACCEPT,
        message: 'El cliente acepta la oferta seleccionada y continua',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_CANCEL,
        message: 'El cliente rechaza la oferta ',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_PAY_DATE,
        message: 'El cliente elige la fecha de pago',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TYC,
        message: 'El cliente acepta las condiciones',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_SAVE,
        message: 'Se guarda la selección del cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_SAVE_ERR,
        message: 'Error al guardar la selección del cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_OFFER_DECLINED,
        message: 'Cliente con marca en evualador',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_FAM,
        message: 'Inicio API Oferta Familia',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_FAM_ERR,
        message: 'Error API Oferta Familia',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_PROD,
        message: 'Inicia API Oferta Producto',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_PROD_ERR,
        message: 'Error API Oferta Producto',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_CHANGE_STATE,
        message: 'Se inicia cambio de estado Oferta a Personalización',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_CHANGE_STATE_ERR,
        message: 'Error en cambio de estado Oferta a Personalización',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_PERSO_INIT,
        message: 'Inicia PersonalizaciónMP',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_PERSO_ERR,
        message: 'Error PersonalizaciónMP',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_ALTAMP_INIT,
        message: 'Inicia AltaMP',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_ALTAMP_ERR,
        message: 'Error AltaMP',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_PERS_CHANGE,
        message: 'Inicia cambio de estado Personalizacion a Documentacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_PERS_CHANGE_ERR,
        message: 'Error cambio de estado Personalizacion a Documentacion',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_OFFERMP,
        message: 'Se inciia proceso de Oferta MP',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_TC_OFFERMP_ERR,
        message: 'Error en proceso Oferta MP',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.OFFER_RETRACTO_CONFIRM,
        message: 'El usuario confirma el retracto',
        category: MixpanelCategory.USER,
        identified: 'Si'
      }
    ];
  }

  private contractEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.CONTRACT_INIT,
        message: 'El cliente inicia el proceso de firma de contrato TC',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_TYC,
        message: 'El cliente selecciona "He leído terminos y condiciones"',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_ACCEPT,
        message: 'El cliente acepta contrato',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_CANCEL,
        message: 'El cliente rechaza contrato',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_TOC_INIT,
        message: 'Se inicia la app de toc',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_TOC_RESPONSE,
        message: 'La app de toc llama a la app del santander',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_TOC_RESULT,
        message: 'Se valida el resultado de la app de toc',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_SIGN_A,
        message: 'Se realiza la primera firma del contrato',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_SIGN_B,
        message: 'Se realiza la segunda firma del contrato',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_ANNEX_SIGN,
        message: 'Se realiza la firma del anexo',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_UPLOAD,
        message: 'Se sube el contratoEl cliente cancela y retrocede',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_ANNEX_UPLOAD,
        message: 'Se sube el anexo',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_SAVE_STATE,
        message: 'Se guarda el estado para la retoma',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_NEXT,
        message: 'Continua al curse',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_GET_DOCUMENT,
        message: 'Se recuperan los documentos',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_GET_DOCUMENT_ERR,
        message: 'Error al recuperar los archivos',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_INSTRUCTIONS_TOC,
        message: 'Acepta instrucciones de TOC flujo contrato',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_SESSION_MANG_INIT,
        message: 'Se inicia session manager proceso Contratacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_SESSION_MANG_ERR,
        message: 'Error en session manager proceso Contratacion',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_LIVENESS_INIT,
        message: 'Se inicia Liveness en proceso Contratacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_LIVENESS_ERR,
        message: 'Error Liveness en proceso Contratacion',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_SIGN_INIT,
        message: 'Se inicia firma en proceso Contratacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_SIGN_ERR,
        message: 'Error firma en proceso Contratacion',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_CHANGE_STATE_INIT,
        message: 'Se inicia cambio de estado en proceso Contratacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CONTRACT_CHANGE_STATE_ERR,
        message: 'Error en cambio de estado en proceso Contratacion',
        category: MixpanelCategory.API,
        identified: 'Si'
      }
    ];
  }

  private curseEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.CURSE_INIT,
        message: 'El cliente va al curse',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CURSE_REGISTER,
        message: 'Se realiza el curse',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CURSE_REGISTER_ERROR,
        message: 'Error al realizar el curse',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CURSE_READY,
        message: 'El cliente finaliza el proceso de contratación',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CURSE_END,
        message: 'Se finaliza el flujo de solicitud',
        category: MixpanelCategory.USER,
        identified: 'Si'
      }
    ];
  }

  private simulateEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.SIMULATE_EXIT,
        message: 'El cliente abandona la simulación',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SIMULATE_INIT,
        message: 'El cliente inicia la simulacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SIMULATE_RESULT,
        message: 'Resultado de la simulación',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SIMULATE_RESULT_ERR,
        message: 'El cliente recibe una simulacion',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SIMULATE_CONFIRM,
        message: 'El cliente confirma la simulacion',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SIMULATE_CONFIRM_ERR,
        message: 'Error al confirmar la simulación',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SIMULATE_PAY_INIT,
        message: 'Inicio flujo de pago',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.SIMULATE_VALIDA_CLIENTE_INIT,
        message: 'Inicia validación de cliente',
        category: MixpanelCategory.USER,
        identified: 'No'
      },
      {
        event: MixpanelEvent.SIMULATE_VALIDA_CLIENTE_ERR,
        message: 'Error al validar cliente',
        category: MixpanelCategory.API,
        identified: 'No'
      },
      {
        event: MixpanelEvent.SIMULATE_OFERTA_INIT,
        message: 'Inicia oferta cliente pago',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.SIMULATE_OFERTA_ERR,
        message: 'Error al traer oferta cliente pago',
        category: MixpanelCategory.API,
        identified: 'Si'
      }
    ];
  }

  private paysmsEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.PAYSMS_EXIT,
        message: 'El cliente abandona el ingreso del sms',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_INIT,
        message: 'El cliente ingresa a la validación sms',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_GET_PHONE,
        message: 'Se obtiene el telefono del cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_GET_PHONE_ERR,
        message: 'Error al obtener el teléfono del cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_SEND,
        message: 'Se envía el código al cliente',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_WAIT,
        message: 'El cliente espera la recepcion sms',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_RESEND,
        message: 'El cliente solicita el reenvio del sms',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_RESEND_ERR,
        message: 'Error en el reenvio del sms',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_ENTER,
        message: 'El cliente ingresa el codigo sms',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_NEXT,
        message: 'El cliente continua',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.PAYSMS_NEXT_ERR,
        message: 'Error al continuar la validación',
        category: MixpanelCategory.API,
        identified: 'Si'
      }
    ];
  }

  private buyEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.BUY_EXIT,
        message: this.cancelText,
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.BUY_INIT,
        message: 'El cliente ingresa a procesar el pago',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.BUY_TICKET,
        message: 'El cliente ingresa la boleta',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.BUY_END,
        message: 'El cliente finaliza el proceso de pago',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.BUY_END_ERR,
        message: 'Error al procesar el pago',
        category: MixpanelCategory.API,
        identified: 'Si'
      }
    ];
  }

  private cancelEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.CANCEL_EXIT,
        message: this.cancelText,
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CANCEL_INIT,
        message:
          'El cliente ingresa a procesar el El cliente inicia el proceso de anulación',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CANCEL_OK,
        message: 'El cliente finaliza el proceso de anulación',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CANCEL_OK_ERR,
        message: 'Error al finalizar la anulación',
        category: MixpanelCategory.API,
        identified: 'Si'
      }
    ];
  }

  private chashboxEvents(): MixpanelData[] {
    return [
      {
        event: MixpanelEvent.CASHBOK_EXIT,
        message: this.cancelText,
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CASHBOK_INIT,
        message: 'El cliente inicia el proceso de revisión de caja',
        category: MixpanelCategory.USER,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CASHBOK_GET_DATA,
        message: 'La app realiza la consulta a la api de caja',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CASHBOK_GET_DATA_ERR,
        message: 'La api devuelve un error en la consulta de caja',
        category: MixpanelCategory.API,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CASHBOK_DATA_DISPLAY,
        message: 'La app despliega los datos recuperados desde la api',
        category: MixpanelCategory.APP,
        identified: 'Si'
      },
      {
        event: MixpanelEvent.CASHBOK_FINISH,
        message: 'El usuario finaliza la revisión de caja',
        category: MixpanelCategory.USER,
        identified: 'Si'
      }
    ];
  }

  getBankId(rutCommerce: any): string {
    try {
     // console.log("rut:", rutCommerce);
      let bankId;
      if (rutCommerce === '0097036000K') { // SANTANDER  0097036000K WEB000001
        bankId = 'WEB000001';
      } else if (rutCommerce === '00789363307') { // MACONLINE  00789363307 WEB000002
        bankId = 'WEB000002';
      } else if (rutCommerce === '00898622002') { // LATAM      00898622002 WEB000004
        bankId = 'WEB000004';
      } else { // DEFAULT
        bankId = 'WEB000001';
      }
      return bankId;
    } catch (e) {
      console.log('catch exception');
      return 'WEB000001';
    }

  }
}
