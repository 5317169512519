import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';
import { retry} from 'rxjs/operators';
import { EncryptHelper } from 'src/app/shared/helpers/encrypt.helper';
import { PlatformLocation } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ApicomercialProxyService {
  env: any;
  aur: string;

  constructor(
    private http: HttpClient,
    private envParams: EnvironmentParamsService,
    private platformLocation: PlatformLocation
  ) {

    this.env = this.envParams.getEnvironment() as { aur: String };
    this.aur=  this.env.aur !== undefined && this.env.aur != ""? this.env.aur : new EncryptHelper(this.platformLocation).decryptAESHex2("2661E432581C84D3B57FF6065902FFC3E97183C78D7F147905122E711EB22E3ADF813EA0BB8EDB6270502AEC7D5BFB9C");

   }

  apiComercialProxy(params: any): Observable<any> {
    const url = `${this.aur}/sancl/partner/marketingcloud/comercial_info`;
    return this.http.post(url, params).pipe(retry(2));
  }


}
