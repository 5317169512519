export enum TealiumUrl {
    // ONBOARDING
    TEA_URL_WELCOME = '/publico/productos/tarjetas/credito/contratar/bienvenida',
    TEA_URL_RUT = '/publico/productos/tarjetas/credito/contratar/validacion-identidad/rut',
    TEA_URL_INIT = '/publico/productos/tarjetas/credito/contratar/validacion-identidad/toc/inicio',
    TEA_URL_TOC_BIOMETRIC = '/publico/productos/tarjetas/credito/contratar/validacion-identidad/toc/biometria',
    TEA_URL_CI_FRONT = '/publico/productos/tarjetas/credito/contratar/validacion-identidad/toc/cedula-anverso',
    TEA_URL_CI_BACK = '/publico/productos/tarjetas/credito/contratar/validacion-identidad/toc/cedula-reverso',
    TEA_URL_TOC_OK = '/publico/productos/tarjetas/credito/contratar/validacion-identidad/toc/exito',
    TEA_URL_TOC_KO = '/publico/productos/tarjetas/credito/contratar/validacion-identidad/toc/rechazo',
    TEA_URL_CLIENT_DATA = '/publico/productos/tarjetas/credito/contratar/confirmacion-datos',
    TEA_URL_DECLINE = '/publico/productos/tarjetas/credito/contratar/rechazo',
    TEA_URL_CARD_SELECTION = '/publico/productos/tarjetas/credito/contratar/seleccion-tarjeta',
    TEA_URL_CONTRACT = '/publico/productos/tarjetas/credito/contratar/contrato',
    TEA_URL_INSTRUCTIONS = '/publico/productos/tarjetas/credito/contratar/contrato/firma/instrucciones',
    TEA_URL_SIGN_BIOMETRIC = '/publico/productos/tarjetas/credito/contratar/contrato/firma/biometria',
    TEA_URL_SIGN_OK = '/publico/productos/tarjetas/credito/contratar/contrato/firma/exito',
    TEA_URL_COMPRT = '/publico/productos/tarjetas/credito/contratar/comprobante',
    TEA_URL_ERROR = '/publico/productos/tarjetas/credito/contratar/error',
    TEA_URL_DECLINE_CAPT = '/publico/productos/tarjetas/credito/contratar/rechazo-captura',
    TEA_URL_DECLINE_SIGN = '/publico/productos/tarjetas/credito/contratar/contrato/firma/rechazo',
    TEA_URL_SMS = '/publico/productos/tarjetas/credito/contratar/confirmacion-datos/sms',
    TEA_URL_SMS_OK = '/publico/productos/tarjetas/credito/contratar/confirmacion-datos/sms-exito',
    // PAYMENT
    TEA_URL_PAY_RUT = '/publico/productos/tarjetas/credito/pagar/bienvenida',
    TEA_URL_PAY_RUT_KO = '/publico/productos/tarjetas/credito/pagar/rechazo-comercial',
    TEA_URL_PAY_SIMULATION = '/publico/productos/tarjetas/credito/pagar/simulador',
    TEA_URL_PAY_SIMULATIONS = '/publico/productos/tarjetas/credito/pagar/simulacion',
    TEA_URL_PAY_SIMULATION_KO = '/publico/productos/tarjetas/credito/pago/simulacion/rechazo',
    TEA_URL_PAY_SMS = '/publico/productos/tarjetas/credito/pagar/autorizacion',
    //TEA_URL_PAY_SMS_OK = '/publico/productos/tarjetas/credito/pago/simulacion/sms-exito',
    TEA_URL_PAY_COMPRT = '/publico/productos/tarjetas/credito/pagar/comprobante',
    TEA_URL_PAY_ERROR = '/publico/productos/tarjetas/credito/pagar/error'
}
