<div libAddFullLoading></div>
<div style="position: relative;z-index: 1;">
    <mat-toolbar color="primary">
        <div class="santanderLogo"><img src="../../assets/logosantander.png" height="25" alt=""></div>
        <div class="spacer"></div>
        <div class="comerce" style="font-family:'Santander Text';font-size:18px;">{{this.storeName}}</div>
    </mat-toolbar>
</div>

<div style="position: relative;z-index: 1;" *ngIf="check_browser_so() == false">
    <div class="alert alert-danger text-justify" >
        Navegador no disponible. Asegúrate de usar un navegador habilitado, Safari para dispositivos iOS o Chrome para dispositivos Android, y vuelve a intentarlo en otro momento.
    </div>
</div>

<div class="grid" gdColumns.gt-sm="repeat(12, 1fr)" gdColumns.sm="repeat(8, 1fr)" gdColumns.xs="repeat(4, 1fr)"
    gdGap="1.6rem" ngClass.xs="mobile" ngClass.sm="tablet" ngClass.md="laptop" ngClass.gt-md="desktop"
    style="color:white" [ngStyle.md]="{'min-height': '900px'}" [ngStyle.gt-md]="{'min-height': '900px'}">
    <div gdColumn="1/span 5" gdColumn.md="1/span 5" gdColumn.sm="1/span 8" gdColumn.xs="1/span 4"
        style="height: 400px;z-index: 100">
        <div class="tcv-welcome" [ngStyle.sm]="{'margin-top': '150px'}" [ngStyle.xs]="{'margin-top': '150px'}">
            Bienvenido</div>
        <div class="tcv-subtitle" [ngStyle.sm]="{'font-size': '30px'}" [ngStyle.xs]="{'font-size': '30px'}"
            [ngStyle.md]="{'font-size': '36px'}">
            Ahora en 4 simples pasos <br>
            podrás tener tu Tarjeta de <br>
            Crédito Santander<br>
            <b>100% online</b>
        </div>

        <div class="container-card" *ngIf="false">
            <div class="body">
                <div class="tcv-steps">
                    <div class="title" fxLayout="column" fxLayoutAlign="start center" style="text-align: center;"
                        style="height: 55px;" *ngIf="false"><span>Abre tu
                            Tarjeta de
                            Crédito en solo 4
                            pasos</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column"
                        fxLayoutAlign.xs="space-around center">
                        <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="space-between center"
                            style="margin-top:24px;">
                            <div class="numberCircle" fxLayout="column" fxLayoutAlign="center center">
                                <mat-icon class="icon-FUNC008" style="font-size: 40px;">
                                </mat-icon>
                            </div>
                            <span class="title-steps" style="text-align: center;">Valida tu identidad</span>
                        </div>
                        <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="space-between center"
                            style="margin-top:24px;">
                            <div class="numberCircle" fxLayout="column" fxLayoutAlign="center center">
                                <mat-icon class="icon-SYS001" style="font-size:40px;">
                                </mat-icon>
                            </div>
                            <span class="title-steps" style="text-align: center;">Confirma tus datos</span>
                        </div>
                        <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="space-between center"
                            style="margin-top:24px;">
                            <div class="numberCircle" fxLayout="column" fxLayoutAlign="center center">
                                <mat-icon class="icon-FUNC018" style="font-size:40px;">
                                </mat-icon>
                            </div>
                            <span class="title-steps" style="text-align: center;">Selecciona tu Tarjeta</span>
                        </div>
                        <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="space-between center"
                            style="margin-top:24px;">
                            <div class="numberCircle" fxLayout="column" fxLayoutAlign="center center">
                                <mat-icon class="icon-DOC003" style="font-size:40px;">
                                </mat-icon>
                            </div>
                            <span class="title-steps" style="text-align: center;">Firma contrato y anexo</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.xs="center center"
            style="position: relative;margin-top:16px;margin-left: 36px;" [ngStyle.xs]="{'margin-left': '0px'}">
            <div style="z-index: 1;" fxLayout="row" fxLayoutAlign="start start">
                <mat-checkbox style="margin-right: 16px;" (change)="enableButton($event)" [(ngModel)]="checked">
                </mat-checkbox>
                <u (click)="showDisclaimer()" (keydown)="onKeyDown($event)" >Autorizo la obtención de datos (Ver más)</u>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center"
            style="margin-top:16px;margin-left: 36px;" [ngStyle.xs]="{'margin-left': '0px'}">
            <button mat-button class="base-primary icons-right"
                style="margin-left:8px;margin-right:8px; margin-top: 16px;z-index: 1;" [disabled]="!enableNext"
                (click)="goToToc()" [ngStyle.xs]="{'width': '300px'}">
                COMENZAR
                <em class="icon-SYS016"></em>
            </button>
            <div style="margin-top: 16px;z-index: 1;">
                <span
                    style="color:#fff !important;text-shadow: 0px 0px 5px rgba(0,0,0,.4);font-size: 16px;font-family: 'Santander Micro Text';"
                    (click)="showReq()" (keydown)="onKeyDown($event)" >Ver
                    requisitos</span>
                <em class="icon-SYS016"
                    style="color: #ec0000;font-size: 16px;font-family: 'Santander Micro Text';"></em>
            </div>
        </div>

        <div style="text-align: center;" style = "display:none !important;" >
          <button  id="botonCaptchaInvisible" (click)="resolvedCaptchaInvisible()">Important action</button>
        </div>

        <br>
        <br>
        <div class="productos-list mobile">
            <div class="" valign="middle" style="text-align: center;">
                <ul class="" style="width: 720px;">
                    <li class="" style="width: 140px;margin-left:16px;margin-right:16px;"><span aria-hidden="true"
                            class="icon-FUNC008" style="font-size: 50px;color:white;"></span>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <div class="numberCircle">1</div>
                            <div tabindex="-1" aria-hidden="true" class="d-block"
                                style="color:white;font-size:16px;font-family:'Santander Micro Text'">Valida
                                tu identidad</div>
                        </div>
                    </li>
                    <li class="" style="width: 140px;margin-left:16px;margin-right:16px;"><span aria-hidden="true"
                            class="icon-SYS001" style="font-size: 50px;color:white;"></span>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <div class="numberCircle">2</div>
                            <div tabindex="-1" aria-hidden="true" class="d-block"
                                style="color:white;font-size:16px;font-family:'Santander Micro Text'">Confirma tus
                                datos
                            </div>
                        </div>
                    </li>
                    <li class="" style="width: 140px;margin-left:16px;margin-right:16px;"><span aria-hidden="true"
                            class="icon-FUNC018" style="font-size: 50px;color:white;"></span>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <div class="numberCircle">3</div>
                            <div tabindex="-1" aria-hidden="true" class="d-block"
                                style="color:white;font-size:16px;font-family:'Santander Micro Text'">
                                Selecciona tu
                                Tarjeta
                            </div>
                        </div>
                    </li>
                    <li class="" style="width: 160px;margin-left:16px;margin-right:16px;"><span aria-hidden="true"
                            class="icon-DOC003" style="font-size: 50px;color:white;"></span>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <div class="numberCircle">4</div>
                            <div tabindex="-1" aria-hidden="true" class="d-block"
                                style="color:white;font-size:16px;font-family:'Santander Micro Text';margin-left:8px">
                                {{step4}}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>
