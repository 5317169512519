import { Injectable } from '@angular/core';
import { from, Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../storage.service';
import { TocProxyService } from './toc-proxy.service';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';
import { CapturaService } from '../captura/captura.service';
import { TealiumUrl } from '../../interfaces/tealium.num';
import { TealiumTrackService } from '../tealium/tealium-track.service';
import { EncryptHelper } from 'src/app/shared/helpers/encrypt.helper';
import { PlatformLocation } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class TocService {

  env: any;
  tci2: string;
  tci: string;
  tcs2: string;
  tcs: string;
  esc2: string;
  esc: string;

  private sessionId = new BehaviorSubject<string>(null);
  private selfie = new BehaviorSubject<string>(null);
  private id_front = new BehaviorSubject<string>(null);
  private id_back = new BehaviorSubject<string>(null);
  private typeDoc = new BehaviorSubject<string>(null);
  private key = new BehaviorSubject<string>(null);
  private iQueryId = new BehaviorSubject<string>(null);

  constructor(
    private tocProxy: TocProxyService,
    private storage: StorageService,
    private envParams: EnvironmentParamsService,
    private captura: CapturaService,
    private tealium: TealiumTrackService,
    private platformLocation: PlatformLocation
  ) {

    this.env = this.envParams.getEnvironment() as { prd: boolean, eci: string, am: string};

    this.tci2= this.env.prd?
    '145F64A09AD12962A4942822F80544ABE0BDB75B16ADD2B7BE600ABA007253BDA1C01381C42F3954D0FF4765EF8F124E':
    '6E81FD05921222D6FE06215608FFFBE420F3BAF43369EE13E8904F0B63CED2E98C499AEEE5BC5D8527E28BC45BACC441' ;
    this.tci = this.decryp(this.tci2);

    this.tcs2= this.env.prd?
    'BF4D7D1CE85C8C2582336E317841B6F72313B752105449F6A15A73638597A5C4AD70876E3856BD6E4B92D71FCB96F41D1B8B54B074E38C71B296DD1D696BE6F7':
    '08022DAC5B943325692CED84B06FCF401A42815832658F9E2943DEB8CB8483532E0ECE156E70EBC05D2CC3752E54E879C7E211EFCFC0C2CA6C4F4B5933EA0FE9';
    this.tcs = this.decryp(this.tcs2);

    this.esc2= this.env.prd?
    'F5AEE7B1E740A908D3A6596658631D579FA21744512E679E83079A27C84E6A7249D3C48B0904D0EC885171BA9DD04D6149FA16F234A1C0ADD7AABBF3CD75D521':
    'FB494CB211D5CDC36009414C423683D40F9D678B122641FE0AB1617F4921A41110C692D5FB0B32F7901471E88F4290D741EC11A35EB49A11A740259F6CC4D670';
    this.esc = this.decryp(this.esc2);

  }


  tokenTOC(tokenType: string): Observable<any> {

    this.storage.setItem('token-access_token', '');

    const params = {
      grant_type: "client_credentials",
      scope: "Internet_Clientes",
      client_id: this.tci,
      client_secret: this.tcs
    };
    if (tokenType !== 'vCI') {
      params.scope = 'Internet_Personas';
    }
    if (tokenType === 'eFX') {
      console.log('se usa VARIABLE: eqxAPI_client_id');
      params.client_id = this.env.eci;
      params.client_secret = this.esc;
      params.grant_type = "client_credentials";
      params.scope = "Internet_Clientes_Persona";
    }
    return from(this.tocProxy.getTokenTOCProxy(params)).pipe(
      map(response => {
        console.log('tokenTOC', response);
        this.storage.setItem('token-access_token', response.access_token);
        return response;
      })
    );
  }

  validaCI(rut: string, serie: string): Observable<any> {
    return from(this.tocProxy.validaCIProxy(rut, serie)).pipe(
      map(response => {
        console.log('validateCI', response);
        this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
        return response;
      })
    );
  }

  sessionManager(): Observable<any> {
    let node =  this.env.am === "L"? 4 : 1;
    const params = {
      "autocaptura": true,
      "liveness": true,
      "fake_detector": true,  // quitar parametro y probar
      "mode": node
    };

    return from(this.tocProxy.sessionManagerProxy(params)).pipe(
      map(response => {
        console.log('sessionManager', response);
        this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
        return response;
      })
    );
  }

  validaFacial(docType: string, selfie: string, id_front: string, id_back: string): Observable<any> {
    const params = {
      documentType: docType,
      selfie: selfie,
      id_front: id_front,
      id_back: id_back
    };
    return from(this.tocProxy.validaFacialProxy(params)).pipe(
      map(response => {
        console.log('validaFacial', response);
        return response;
      })
    );
  }

  startTransaction(rutCliente, serie): Observable<any> {
    return from(this.tocProxy.startTransactionProxy(rutCliente, serie)).pipe(
      map(response => {
        console.log('startTransaction', response);
        return response;
      })
    );
  }

  interactiveQueryResponse(key: string, iQueryId: string, answerId: any[]): Observable<any> {
    const params = {
      "iQAnswerRequest": {
        "interactiveQueryResponse": {
          "answers": [
            {
              "answerId": answerId[0],
              "questionId": 1
            },
            {
              "answerId": answerId[1],
              "questionId": 2
            },
            {
              "answerId": answerId[2],
              "questionId": 3
            }
          ]
        },
        "interactiveQueryId": iQueryId,
        "transactionKey": key
      }
    }
    return from(this.tocProxy.interactiveQueryResponseProxy(params)).pipe(
      map(response => {
        this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
        console.log('interactiveQueryResponse', response);
        return response;
      })
    );
  }

  signatureFacial(rutCliente, codigoCaso, selfieb64, pag, posX, posY, gnId, idPros, option): Observable<any> {
    console.log('rutCliente2:',rutCliente);
    console.log('codigoCaso:',codigoCaso);
    console.log('selfieb64:',selfieb64);
    console.log('pag:',pag);
    console.log('posX:',posX);
    console.log('posY:',posY);
    console.log('gnId:',gnId);
    console.log('idPros:',idPros);
    console.log('option:',option);

    const params = {
      OBFirmadorRequest: {
        FirmadorRequest: {
          CanalLogico: '', //anterior->  CanalLogico: this.env.canal_logico,
          RutCliente: rutCliente,
          FORMQUESTID_TOKEN: 'ST02', //anterior->   FORMQUESTID_TOKEN: this.env.FORMQUESTID_TOKEN,
          GN_ID: gnId,
          IdProspect: idPros,
          GuardarFotoCliente: option,
          FotoCliente: selfieb64,
          Email: this.captura.getMail(),
          TipoDocumental: "CONT_COND_TC",
          UsuarioRACF: '', //anterior->  UsuarioRACF: this.env.usuario_altair,
          IdSolicitud: {
            ID_CASO_PN: {
              CENTRO: {
                EMPRESA: '', //anterior-> EMPRESA: this.env.empresa,
                CENTRO: '' //anterior->  CENTRO: this.env.centro
              },
              CODIGO_DE_CASO: codigoCaso,
              VERSION: '00'
            },
            CODIGO_DE_SOLICITUD: '001'
          }
        },
        ListaParametro: {
          Firma: {
            Pagina: pag,
            PosX: posX,
            PosY: posY
          }
        }
      }
    };

    return from(this.tocProxy.signatureFacialProxy(params)).pipe(
      map(response => {
        this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
        console.log('signatureFacial', response);
        return response;
      })
    );
  }

  validaCIreCaptcha(rut: string, serie: string, token: string): Observable<any> {
    const params = {
      "rut": rut,
      "numeroSerie": serie,
      "tokenRecaptcha": token,
      "codigoAplicacion": "08"
    };
    this.storage.setItem('token-access_token', '');
    return from(this.tocProxy.validaCIreCaptchaProxy(params)).pipe(
      map(response => {
        this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
        console.log('validateCIreCaptcha', response);
        return response;
      })
    );
  }

  getTokenTCVRecaptcha(tokenRecaptcha: string, command: string): Observable<any> {
    const params = {
      "tokenRecaptcha": tokenRecaptcha,
      "command": command,
    };
    return from(this.tocProxy.getTokenTCVRecaptchaProxy(params)).pipe(
      map(response => {
        console.log('getTokenTCVRecaptcha', response);
        return response;
      })
    );
  }

  getSessionId(): string {
    return this.sessionId.getValue();
  }

  setSessionId(id: string) {
    this.sessionId.next(id);
  }

  getSelfie(): string {
    return this.selfie.getValue();
  }

  setSelfie(id: string) {
    this.selfie.next(id);
  }

  getIdFront(): string {
    return this.id_front.getValue();
  }

  setIdFront(id: string) {
    this.id_front.next(id);
  }
  getIdBack(): string {
    return this.id_back.getValue();
  }

  setIdBack(id: string) {
    this.id_back.next(id);
  }

  getTypeDoc(): string {
    return this.typeDoc.getValue();
  }

  setTypeDoc(id: string) {
    this.typeDoc.next(id);
  }

  getKey(): string {
    return this.key.getValue();
  }

  setKey(id: string) {
    this.key.next(id);
  }

  getIQueryId(): string {
    return this.iQueryId.getValue();
  }

  setIQueryId(id: string) {
    this.iQueryId.next(id);
  }

  trackError(cod: string, flow: string) {
    let errors: any = {};
    if (flow === "liveness") {
      errors = this.errorsToc.Liveness;
    } else if (flow === "autocaptura") {
      errors = this.errorsToc.Autocaptura;

    } else if (flow === "facialCI") {
      errors = this.errorsToc.FacialCI;
    } else if (flow === "firma") {
      errors = this.errorsToc.Liveness;
    }
    let data = errors.find(el => el.id === cod);
    if (data === undefined) {
      this.tealium.trackEvent(TealiumUrl.TEA_URL_TOC_KO, '', '', '', flow + ' - ' + 'Error interno', '');
    } else {
      this.tealium.trackEvent(TealiumUrl.TEA_URL_TOC_KO, '', '', '', flow + ' - ' + data.error, '');
    }
  }

  errorsToc = {
    Liveness: [
      { id: "402", error: "Tiempo límite excedido",
      data:[
        { extra: "701", error: "internal error",
        titulo : "Tu rostro no ha sido identificado" ,
        detalle : "Error al identificar tu rostro.<br>Inténtalo nuevamente.<br>Error: 701",
        btn : "REINTENTAR" },
        { extra: "703", error: "blurred Face",
        titulo : "Rostro borroso",
        detalle : "Tu rostro se ve borroso.<br>Inténtalo nuevamente.<br>Error: 703",
        btn : "REINTENTAR" },
        { extra: "704", error: "face not in frame",
        titulo : "Rostro no centrado",
        detalle : "Tu rostro no esta centrado en el recuadro.<br>Inténtalo nuevamente.<br>Error: 704",
        btn : "REINTENTAR" },
        { extra: "705", error: "face not found",
        titulo : "Rostro no encontrado" ,
        detalle : "Tu rostro completo no fue detectado por la cámara.<br>Inténtalo nuevamente.<br>Error: 705",
        btn : "REINTENTAR" },
        { extra: "706", error: "zoom in face",
        titulo : "Rostro muy lejos" ,
        detalle : "Tu rostro se encuentra muy lejos de la cámara,<br>acércate a la mascara e inténtalo nuevamente.<br>Error: 706",
        btn : "REINTENTAR"}
        ]
      },
      { id: "403", error: "Cancelado" },
      { id: "404", error: "Internet" },
      { id: "405", error: "Sesión"},
      { id: "406", error: "Endpoint" },
      { id: "407", error: "Cámara" },
      { id: "408", error: "Browser no soportado" },
      { id: "411", error: "Parámetros" },
      { id: "430", error: "Inválido" },
      { id: "450", error: "Error" },
    ],
    Autocaptura: [
      { id: "200", error: "Proceso concluyó sin interrupciones" },
      { id: "401", error: "Envío de datos fallido" },
      { id: "402", error: "Tiempo límite excedido",
      data:[
        { extra: "601", status: "The document has not been detected",
        titulo : "Cédula de identidad no capturada" ,
        detalle : "No hay cédula de identidad en el recuadro.<br>Inténtalo nuevamente.<br>Error: 601",
        btn : "REINTENTAR" },
        { extra: "602", status: "Adjust the document within the frame",
        titulo : "Cédula de identidad no centrada" ,
        detalle : "La cédula de identidad no está centrada en el recuadro.<br>Inténtalo nuevamente.<br>Error: 602",
        btn : "REINTENTAR" },
        { extra: "603", status: "The image odf the docunent is blurred",
        titulo : "Imagen de cédula de identidad borrosa" ,
        detalle : "La imagen de la cédula de identidad está borrosa.<br>Inténtalo nuevamente.<br>Error: 603",
        btn : "REINTENTAR" },
        { extra: "604", status: "Get your document closer to the camera",
        titulo : "Cédula de identidad muy lejos" ,
        detalle : "La cédula de identidad está muy lejos de la cámara.<br>Inténtalo nuevamente.<br>Error: 604",
        btn : "REINTENTAR" },
        { extra: "605", status: "Poor quality capture",
        titulo : "Cédula de identidad en mal estado" ,
        detalle : "Cédula de identidad en mal estado, no es posible validar.<br>Inténtalo nuevamente.<br>Error: 605",
        btn : "REINTENTAR"},
        ]
      },
      { id: "403", error: "Cancelado por usuario" },
      { id: "404", error: "Sin internet" },
      { id: "405", error: "Sesión expirada"},
      { id: "406", error: "No hay conexión con servidor (ws)" },
      { id: "407", error: "Cámara no disponible" },
      { id: "408", error: "Browser no soportado" },
      { id: "411", error: "Falta ​session_id" },
      { id: "412", error: "Falta ​document_type" },
      { id: "413", error: "Falta ​document_side" },
      { id: "414", error: "Falta ​iOS delegate" },
      { id: "415", error: "Falta ​iOS controller" },
      { id: "421", error: "session_id​ no válido" },
      { id: "422", error: "document_type​ no soportado" },
      { id: "430", error: "La cédula no pudo ser validada" },
      { id: "431", error: "session_id​ ya utilizado" },
      { id: "450", error: "Error de plugin web" },
      { id: "451", error: "Cuadro / Frame inválido" },
      { id: "502", error: "Error de camara" },


    ],
    FacialCI: [
      { id: "400", error: "Request mal formado" },
      { id: "401", error: "Servicio no habilitado" },
      { id: "402", error: "Límite de transacciones o timeout" },
      { id: "405", error: "Transacción posee match negativo" },
      { id: "406", error: "Transacción no pertenece a cliente con apiKey" },
      { id: "407", error: "Transacción inválida" },
      { id: "408", error: "El Content Type enviado no es soportado por la API" },
      { id: "410", error: "No se encontró apiKey" },
      { id: "411", error: "No se encontró documentType" },
      { id: "412", error: "No se encontró photo" },
      { id: "413", error: "No se encontró toc_token" },
      { id: "414", error: "No se encontró id_front" },
      { id: "415", error: "No se encontró id_back" },
      { id: "416", error: "No se encontró selfie" },
      { id: "417", error: "No se encontró dni" },
      { id: "418", error: "No se encontró nfcImage" },
      { id: "421", error: "apiKey ​no existe" },
      { id: "422", error: "documentType​ incorrecto" },
      { id: "423", error: "id_front​ no es imagen JPG" },
      { id: "424", error: "id_back​ no es imagen JPG" },
      { id: "425", error: "selfie​ no es imagen JPG" },
      { id: "426", error: "photo​ no es imagen JPG" },
      { id: "427", error: "toc_token​ no existe" },
      { id: "428", error: "token liveness no existe" },
      { id: "429", error: "token liveness idénticos" },
      { id: "430", error: "token de autocaptura no existe" },
      { id: "431", error: "Fotos idénticas (i​ d_front​ y s​ elfie​)" },
      { id: "434", error: "Una o ambas fotografías del documento enviadas no corresponde al documentType declarado" },
      { id: "435", error: "id_front e ​id_back no corresponden al mismo documento" },
      { id: "437", error: "El contenido de los códigos de barras no coincide con el contenido de la sección MRZ" },
      { id: "438", error: "El RUT y número de documento no calzan" }
    ],
    Sign: []
  }

  decryp(a){
    return  new EncryptHelper(this.platformLocation).decryptAESHex(a);
  }
}
