import { Injectable } from '@angular/core';
import { TealiumService } from '@santander/tealium';
import { StorageService } from '../storage.service';

import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';
@Injectable({
  providedIn: 'root'
})
export class TealiumTrackService {
  env: any;

  constructor(
    private readonly tealium: TealiumService,
    private storage: StorageService,
    private envParams: EnvironmentParamsService,
  ) {
    this.env = this.envParams.getEnvironment() as { urc: string };
   }

  init(id: string) {
    if (id === '' || id === undefined || id === null) {
      id = this.makeId(20).toString();
      this.storage.setItem('flowIdTealium', id);
     // console.log('flwId:', this.storage.getItem('flowIdTealium'));
    }
   // console.log('id_tealium', id);
    this.tealium.initialize(id);
  }

  trackEvent(page: string, card: string, flow: string, id: string, name: string, descr: string) {

    const event = {
      page_name: 'Tarjetas',
      page_path: page,
      tarjeta: card,
      flujo: this.storage.getItem('campaign'),
      //tipo_flujo: this.storage.getItem('campaign'),
      id: id,
      nombre: name,
      glosa: descr,
      origen: this.getDomain(this.env.urc)
    }
    const obj = Object.entries(event).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
    console.log('trackEvent tealium ', obj)
    this.tealium.trackView(obj);

  }

  trackEventPayment(page: string, card: string, flow: string, id: string, name: string, descr: string, cuota: string, numero_cuotas: string, tasa: string, monto: string) {
        const event = {
          page_name: 'Tarjetas',
          page_path: page,
          tarjeta: card,
          monto: monto,
          cuota: cuota,
          numero_cuotas: numero_cuotas,
          tasa: tasa,
          flujo: this.storage.getItem('campaign'),
          //tipo_flujo: this.storage.getItem('campaign'),
          id: id,
          nombre: name,
          glosa: descr,
          origen: this.getDomain(this.env.urc)
        }
        const obj = Object.entries(event).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
        console.log('trackEventPayment tealium', obj)
        this.tealium.trackView(obj);
  }

  trackEventButton(textButton: string) {
    const event = {
      event_category: "Interacciones",
      event_action: "Botón",
      event_label: textButton
    }
    console.log('trackEventButton tealium ', event)
    this.tealium.trackEvent(event);
  }

  trackView(eventView: any) {
    this.tealium.trackView(eventView);
  }

  getDomain(urlCallback: any): string {
    try {
     return new URL(urlCallback).host;
    } catch (e) {
      return 'www.santander.cl';
    }
  }

  private makeId(length: number, uppercase: boolean = false): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return uppercase ? result.toUpperCase() : result;
  }

}
