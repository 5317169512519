import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { GeneralProxyService } from './general-proxy.service';
import { map } from 'rxjs/operators';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';
import { StorageService } from './../storage.service';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  env: any;

  constructor(
    private envParams: EnvironmentParamsService,
    private generalProxy: GeneralProxyService,
    private storage: StorageService
  ) {

    this.env = this.envParams.getEnvironment() as { hzs: string };

   }


  // API - Consulta estado del comercio ->
  validaComercioTC(rutComerce: string, rutSeller: string, idSale: string, amount: string): Observable<any> {
    console.log('monto<-', amount);
    const params1 = {
      'TERMINAL-ALT': '',
      'CANAL-ID': '',
      rutComercio: rutComerce,
      rutSucursal: rutSeller
    }
    const params2 = {
      rutComercio: rutComerce,
      rutSucursal: rutSeller,
      idVenta: idSale,
      monto: amount
    }

    const params = (this.env.hzs === "ON" && idSale !== "" && amount !== "") ? params2 : params1;

    return from(this.generalProxy.validaComercioTCProxy(params)).pipe(
      map(response => {
        console.log('validaComercioTC', response);
        return response;
      })
    );
  } // <- Fin - API - Consulta estado del comercio

  // API - Enrola SMS del cliente ->
  enrolaSMS(rutCliente: string, phoneNumber: string): Observable<any> {
    const params = {
      RutCliente: rutCliente,
      NumeroTelefono: phoneNumber,
      CodigoProceso: 'TCV_VAL',
      NumeroIntentos: '3',
      GlosaMensaje: ''
    }
    return from(this.generalProxy.enrolaSMSProxy(params)).pipe(
      map(response => {
        console.log('enrolaSMS', response);
        return response;
      })
    );
  } // <- Fin - API - Enrola SMS del cliente

  // API - Valida SMS enviado al cliente para darlo de alta ->
  validaSMS(codigo: string, rutCliente: string, idSms: string): Observable<any> {
    return from(this.generalProxy.validaSMSProxy(codigo, rutCliente, idSms)).pipe(
      map(response => {
        console.log('validaSMS', response);
        return response;
      })
    );
  } // <- Fin - API - Valida SMS enviado al cliente

  datosGenerales(alias: string, filtro: string, adicional: string): Observable<any> {
    const params = {
      Entrada: [
        {
          ALIAS: alias,
          FILTRO: filtro,
          ADICIONAL: adicional
        }
      ]
    };
    return from(this.generalProxy.datosGeneralesProxy(params)).pipe(
      map(response => {
        console.log('datosGenerales', response);
        return response;
      })
    );
  }

  cambioEstado(idCaso: string, nombreParametro: string, valorParametro: string): Observable<any> {
    const params = {
      canal: '',  //anterior->  canal: this.env.canal_logico,
      IdSolicitud: {
        ID_CASO_PN: {
          CENTRO: {
            EMPRESA: '', //anterior-> EMPRESA: this.env.empresa,
            CENTRO: ''  //anterior -> CENTRO: this.env.centro
          },
          CODIGO_DE_CASO: idCaso,
          VERSION: '00'
        },
        CODIGO_DE_SOLICITUD: '001'
      },
      estadoCaso: '',
      nombreParametro: nombreParametro,
      valorParametro: valorParametro,
      valorEvento: '',
      procesoNegocio: {
        empresa: '',   //anterior->  empresa: this.env.empresa,
        codProcesoNegocio: ''  //anterior-> codProcesoNegocio: this.env.proceso_negocio
      },
      usuarioRacf: '' //anterior->  usuarioRacf: this.env.usuario_altair
    }
    return from(this.generalProxy.cambioEstadoProxy(idCaso, params)).pipe(
      map(response => {
        console.log('cambioEstado', response);
        return response;
      })
    );
  }

  getEncrypt(data: string): Observable<any> {
    const params = {
      "data": data,
    };
    this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
    return from(this.generalProxy.getEncryptProxy(params)).pipe(
      map(response => {
        console.log('data:', data);
        console.log('encrypt:', response);
        return response;
      })
    );
  }

  getDecrypt(data: string): Observable<any> {
    const params = {
      "data": data,
    };
    this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
    return from(this.generalProxy.getDecryptProxy(params)).pipe(
      map(response => {
        console.log('data:', data);
        console.log('decrypt:', response);
        return response;
      })
    );
  }

  getDataTCV(command: string): Observable<any> {
    this.storage.setItem('token-access_token', this.storage.getItem('token-api'));
    return from(this.generalProxy.getDataTCVProxy(command)).pipe(
      map(response => {
        console.log('getDataTCV', response);
        return response;
      })
    );
  }


}
