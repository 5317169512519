import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NonceService {
  private _nonce: string = '';

  get nonce(): string {
    return this._nonce;
  }

  setNonce(value: string): void {
    this._nonce = value;
  }
}
