import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedireccionService {

  constructor() { }

  redireccionarAURLExterna(url: string) {
    const validDomainsPattern = /^(https:\/\/www\.santander\.cl|https:\/\/www\.latamairlines\.com|https:\/\/www\.maconline\.com)\/.*$/;

    if (validDomainsPattern.test(url)) {
      window.open(url, '_self');
    } else {
      console.error('La URL proporcionada no pertenece a uno de los dominios permitidos:', url);
      // Podrías manejar el error de alguna manera, por ejemplo, mostrar un mensaje al usuario
    }
  }

  actualizarURL(url: string, codigoStatus: string) {
    const urlExterna = this.actualizarParametroQuery(url, 'cod_status', codigoStatus);
    this.redireccionarAURLExterna(urlExterna);
  }

  private actualizarParametroQuery(url: string, parametro: string, valor: string): string {
    const urlObj = new URL(url);
    urlObj.searchParams.set(parametro, valor);
    return urlObj.href;
  }
}
