import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { CapturaProxyService } from './captura-proxy.service';
import { map } from 'rxjs/operators';
import { Maintenance } from '../../interfaces/maintenance';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';

@Injectable({
  providedIn: 'root'
})
export class CapturaService {

  private MAINTENANCE = new BehaviorSubject<Maintenance>(null);
  readonly maintenance$ = this.MAINTENANCE.asObservable();
  private rutCliente = new BehaviorSubject<string>(null);
  private tocFormat = new BehaviorSubject<string>(null);
  private nombrePersona = new BehaviorSubject<string>(null);
  private primerApellido = new BehaviorSubject<string>(null);
  private segundoApellido = new BehaviorSubject<string>(null);
  private mail = new BehaviorSubject<string>(null);
  private celular = new BehaviorSubject<string>(null);
  env: any;


  constructor(
    private capturaProxy: CapturaProxyService,
    private envParams: EnvironmentParamsService
  ) {
    this.env = this.envParams.getEnvironment() as { prd: boolean};
   }

  // API Calls
  infoClient(rutClient: string): Observable<any> {
    return from(this.capturaProxy.infoClientProxy(rutClient)).pipe(
      map(response => {
        return response;
      })
    );
  }

  getClient(rutClient: string, tokenToc: string, tokenFacial: string): Observable<any> {
    const canal_logic = '002';
    const params = {
      ProcesoNegocio: '', //anterior->  ProcesoNegocio: this.env.proceso_negocio,
      Centro: '', //anterior->  Centro: this.env.centro,
      CanalLogico: canal_logic,
      UsuarioEjecutivo: '',  //anterior-> UsuarioEjecutivo: this.env.usuario_altair,
      UsuarioGenerico: '', //anterior-> UsuarioGenerico: this.env.usuario_altair,
      RutCliente: rutClient,
      TipoContratacion: '',  //anterior-> TipoContratacion: this.env.tipo_contratacion,
      TipoCompraDatosExternos: '', //anterior-> TipoCompraDatosExternos: this.env.tipoCompra_datos_externos,
      TokenHuella: this.env.prd ? tokenFacial : 'token',
      TokenFacial: tokenFacial
    }
    return from(this.capturaProxy.getClientProxy(params)).pipe(
      map(response => {
        console.log('getClient', response);
        return response;
      })
    );
  }

  saveClient(maintenance: Maintenance): Observable<any> {
    return from(this.capturaProxy.saveClientProxy(maintenance)).pipe(
      map(response => {
        console.log('saveClient', response);
        return response;
      })
    );
  }

  validateClient(rutClient: string, flow: string, codigoVenta: any, monto : any, commerce: any): Observable<any> {
    return from(this.capturaProxy.validateClientProxy(rutClient, flow,codigoVenta,monto,commerce)).pipe(
      map(response => {
        console.log('validateClient', response);
        return response;
      })
    );
  }

  // Maintance interface
  update(maintenance: Maintenance) {
    this.MAINTENANCE.next(maintenance);
  }

  getValue(): Maintenance {
    return this.MAINTENANCE.getValue();
  }

  setRutCliente(rut: string) {
    this.rutCliente.next(rut);
  }

  getRutCliente(): string {
    return this.rutCliente.getValue();
  }

  setTocFormat(rut) {
    this.tocFormat.next(rut);
  }

  getTocFormat(): string {
    return this.tocFormat.getValue();
  }

  setNombrePersona(text) {
    this.nombrePersona.next(text);
  }

  getNombrePersona(): string {
    return this.nombrePersona.getValue();
  }
  setPrimerApellido(text) {
    this.primerApellido.next(text);
  }

  getPrimerApellido(): string {
    return this.primerApellido.getValue();
  }

  setSegundoApellido(text) {
    this.segundoApellido.next(text);
  }

  getSegundoApellido(): string {
    return this.segundoApellido.getValue();
  }

  setMail(text) {
    this.mail.next(text);
  }

  getMail(): string {
    return this.mail.getValue();
  }

  setCelular(text) {
    this.celular.next(text);
  }

  getCelular(): string {
    return this.celular.getValue();
  }
}
