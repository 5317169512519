import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
//import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';

import { ModalsModule } from '@santander/modals';
import { TabsModule } from "@santander/tabs";

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { EnvironmentParamsService } from '../environments/services/environment-params.service';
import { MixpanelService } from './shared/services/mixpanel/mixpanel.service';

import {ApicomercialProxyService} from './shared/services/apicomercial/apicomercial-proxy.service';
import { TealiumTrackService } from './shared/services/tealium/tealium-track.service';

import { HttpClientModule } from '@angular/common/http';

// App Initializer
export function init_app() {
  if (environment.production) {
    console.clear();
    console.log = () => { };
    console.error = () => { };
    console.info = () => { };
    console.warn = () => { };
    console.debug = () => { };
  }

  return () => { };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
    CoreModule,
    SharedModule,
    ModalsModule,
    FlexLayoutModule,
    TabsModule,
    MatDialogModule
  ],
  providers: [
    MixpanelService,
    TealiumTrackService,
    HttpClientModule,
    ApicomercialProxyService,
    {
      provide: APP_INITIALIZER,
      useFactory: (env: EnvironmentParamsService) => () => env.getEnvVars(),
      deps: [EnvironmentParamsService],
      multi: true
    },
    //{ provide: APP_INITIALIZER, useFactory: init_app, multi: true },
    //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    HttpClientModule,
    { provide: HttpClient, useClass: HttpClient }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
