import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {

    constructor() { }

    private getJSON(url) {
        return fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            cache: 'no-cache'
          });
    }

    public loadConfig(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                // Check for user agent
                let userAgent;
                if (/santander-app/i.test(navigator.userAgent)) {
                    userAgent = 'app';
                } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    userAgent = 'web';
                } else {
                    userAgent = 'desktop';
                }

               // const configFile = `./assets/web/properties.${userAgent}.js`;

                const generateScript = (file?: string) => {
                    let script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = file ? file : 'assets/web/properties.js';
                    script.async = true;
                    script.onload = () => {
                        resolve(true);
                    }
                    script.onerror = (error) => {
                        file ? generateScript() : reject(error);
                    }
                    document.body.appendChild(script);
                }

                // Fetch config
                console.log("Fetching data...");
                /*
                this.getJSON("/UI.Web.HB/data.json")
                    .then(response => {
                        if (!response.ok) {
                            throw new Error("HTTP error " + response.status);
                        }
                        return response.json();
                    })
                    .then(data => {

                        switch (userAgent) {
                          case 'app':
                            if (data['_a']) { // NEW INFRA APP OK
                              generateScript(configFile);
                            } else { // NEW INFRA NOK
                                generateScript();
                            }
                            break;
                          case 'web':
                            if (data['_m']) { // NEW INFRA DESKTOP OK
                              generateScript(configFile);
                            } else { // NEW INFRA NOK
                                generateScript();
                            }
                            break;
                          case 'desktop':
                            if (data['_d']) { // NEW INFRA WEB MOBILE OK
                              generateScript(configFile);
                            } else { // NEW INFRA NOK
                                generateScript();
                            }
                            break;
                          default:
                            generateScript();
                            break;
                        }
                    }).catch(error => {
                        generateScript();
                    });
                    */
                    generateScript();
            } catch (error) {
                reject(error);
            }
        });
    }
}
