import { Component, Renderer2, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NonceService } from './nonce.service';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public sidenav: boolean = false;
  public header: boolean = false;
  public footer: boolean = false;
  env: any;
  nonce: string = '';

  constructor(
    private readonly router: Router,
    private envParams: EnvironmentParamsService,
    private nonceService: NonceService,
    private renderer: Renderer2,
  ) {

    console.log('version: v.0.14.24');
    this.env = this.envParams.getEnvironment() as { prd: boolean, ci: string };
  //  console.log('ci:', this.env.ci);

    console.log('prd:', this.env.prd);

    if (this.env.prd) {
      this.disableConsole();
    }

  }


  ngOnInit() {
    console.log('se da inicio ngOnInit');
    this.generateNonce();

    this.addCSS();
    this.loadScripts();
    this.watchRoute();
  }


  private disableConsole() {
    console.clear();
    console.log('%c ¡Detente! ', 'color: red;font-size: 60px');
    console.log('%c Esta función del navegador está pensada solo para desarrolladores.', 'color: red;font-size: 30px');
    console.log = () => { };
    console.error = () => { };
    console.info = () => { };
    console.warn = () => { };
    console.debug = () => { };
  }

  generateNonce() {
    const randomString = Math.random().toString(36).substring(2, 15);
    this.nonce = btoa(randomString);
    this.nonceService.setNonce(this.nonce);

    //console.log("Valor de nonce:", this.nonce);

  }

  addCSS(): void {
    this.addStyle('assets/fonts/fonts.css');
    this.addStyle('assets/fonts/icon.css');
    this.addStyle('assets/fonts/glider.min.css');
  }

  loadScripts() {
    this.loadScriptWithNonce('https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js', this.nonce);
    const recaptchaSiteKey = '6LcF6MkjAAAAAFCvJRzoTcHHEhxgyFX1q2X44ZQE';
    this.loadScriptWithNonce(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`, this.nonce);
    this.loadScriptWithNonce('./assets/fonts/glider.min.js', this.nonce);
    this.loadScriptWithNonce('./assets/web/properties.js', this.nonce);
    this.loadScriptWithNonce('./assets/menu/menu.js', this.nonce);

    const dataLayerScript = `
      (function(a,s,y,n,c,h,i,d,e){
        s.className += ' ' + y;
        h.start = 1 * new Date;
        h.end = i = function () { s.className = s.className.replace(RegExp(' ?' + y), '') };
        (a[n] = a[n] || []).hide = h;
        setTimeout(function () { i(); h.end = null }, c);
        h.timeout = c;
      })(window, document.documentElement, 'async-hide', 'dataLayer', 4000, { 'GTM-WZ9RLQW': true });
    `;
    this.addScript(dataLayerScript);
  }

private loadScriptWithNonce(src: string, nonce: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.nonce = nonce;

    script.onload = () => {
        console.log(`Script ${src} loaded successfully.`);
    };

    script.onerror = (error) => {
        console.error(`Error loading script ${src}`, error);
    };

    this.renderer.appendChild(document.body, script);
}


  addScript(scriptContent: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.nonce = this.nonce;
    script.textContent = scriptContent;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      console.log('Inline script loaded successfully.');
    };
    document.body.appendChild(script);
  }

  addStyle(href: string): void {
    const style = this.renderer.createElement('link');
    style.rel = 'stylesheet';
    style.href = `./${href}`;
    style.nonce = this.nonce;
    this.renderer.appendChild(document.head, style);
  }

  watchRoute() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(url => {
      window.scrollTo(0, 0);
    });
  }

}
