import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Maintenance } from '../../interfaces/maintenance';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';
import { StorageService } from '../storage.service';
import { EncryptHelper } from '../../helpers/encrypt.helper';
import { retry} from 'rxjs/operators';
import { PlatformLocation } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class CapturaProxyService {

  env: any;
  aur: string;

  constructor(
    private http: HttpClient,
    private envParams: EnvironmentParamsService,
    private storage: StorageService,
    private platformLocation: PlatformLocation
  ) {

    this.env = this.envParams.getEnvironment() as { aur: string, prd: boolean, rtu: string, hzs: string  };
    this.aur=  this.env.aur !== undefined && this.env.aur != ""? this.env.aur : new EncryptHelper(this.platformLocation).decryptAESHex2("2661E432581C84D3B57FF6065902FFC3E97183C78D7F147905122E711EB22E3ADF813EA0BB8EDB6270502AEC7D5BFB9C");
   }

  infoClientProxy(rutClient: string): Observable<any> {
    const tokenFacial = this.storage.getItem('Num_Idf_Toc');
    const path = ((this.storage.getItem('tag') === 'homo' || this.storage.getItem("tag") === "menor") && !this.env.prd) ? "/sancl/partner/clientes/" : "/sancl/partner/clientes/v2/";
    const url = `${this.aur}${path}${this.env.rtu}?tipoConsulta=C&rutEjecutivo=&rutCliente=${rutClient}&fechaSolicitud&fechaAsignacion&estado&sucursal&tipoVal=003&tokenHuella=${tokenFacial}`;
    return this.http.get(url).pipe(retry(2));
  }

  getClientProxy(params: any): Observable<any> {
    let version = '/v2';
    if ((this.storage.getItem('tag') === 'homo' || this.storage.getItem("tag") === "menor") && !this.env.prd) {
      version = '';
    }
    const url2 = `/sancl/partner/clientes${version}/clientes/`;
    const url = `${this.aur}${url2}${this.env.rtu}`;
    return this.http.post(url, params).pipe(retry(2));
  }

  saveClientProxy(params: Maintenance): Observable<any> {
    const url = `${this.aur}/sancl/partner/clientes/v2/${this.env.rtu}/mantenimiento`;
    return this.http.post(url, params).pipe(retry(3));
  }

  validateClientProxy(rutClient: string, flow: string, codigoVenta: any, monto : any, commerce: any): Observable<any> {
    const vhz = (this.env.hzs === "ON" && flow === "PAY") ? "/hz" : "";
    const hz = (this.env.hzs === "ON" && flow === "PAY") ? `&keyHZ=${this.storage.getItem('token-api')}&idVenta=${codigoVenta}&monto=${monto}&rutComercio=${commerce}` : "";
    const url = `${this.aur}/sancl/privado/customer_offer/v1/check_customer_condition/validate${vhz}?rutCliente=${rutClient}${hz}`;
    return this.http.get(url).pipe(retry(2));
  }

}
