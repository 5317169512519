import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';
import { retry} from 'rxjs/operators';
import { EncryptHelper } from '../../helpers/encrypt.helper';
import { PlatformLocation } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class GeneralProxyService {

  env: any;
  ci: string;
  hoa: string;
  aur: string;

   constructor(
    private http: HttpClient,
    private envParams: EnvironmentParamsService,
    private platformLocation: PlatformLocation
  ) {

    this.env = this.envParams.getEnvironment() as { ci: string, hoa: string, aur: string };

    this.ci = this.env.ci !== undefined && this.env.ci != ""? this.env.ci : "oXGpxR5OoWSYwsOLjC58WctnvAjAX1EX";
    this.hoa =  this.env.hoa !== undefined && this.env.hoa != ""? this.env.hoa : new EncryptHelper(this.platformLocation).decryptAESHex2("6AE92021C1F5FECA7723D99722D1F0D4603E180BFC48A53F6C72F9968C61EA80F75E4BF24DC443B069799C5A10F82BDD");
    this.aur =  this.env.aur !== undefined && this.env.aur != ""? this.env.aur : new EncryptHelper(this.platformLocation).decryptAESHex2("2661E432581C84D3B57FF6065902FFC3E97183C78D7F147905122E711EB22E3ADF813EA0BB8EDB6270502AEC7D5BFB9C");

   }

  datosGeneralesProxy(params: any): Observable<any> {
    const url = `${this.aur}/sancl/partner/v2/datosGenerales`;
    return this.http.post(url, params).pipe(retry(1));
  }

  cambioEstadoProxy(caso: string, params: any): Observable<any> {
    const url = `${this.aur}/sancl/partner/v2/solicitudes/${caso}`;
    return this.http.post(url, params).pipe(retry(2));
  }
  enrolaSMSProxy(params: any): Observable<any> {
    const url = `${this.aur}/sancl/partner/v2/enrolamientosms/codigos`;
    return this.http.post(url, params).pipe(retry(2));
  }

  validaSMSProxy(codigo: string, rutCliente: string, idSms: string): Observable<any> {
    const url = `${this.aur}/sancl/partner/v2/enrolamientosms/codigos/${codigo}?rutCliente=${rutCliente}&numeroIdentificador=${idSms}&codigoProceso=TCV_VAL`;
    return this.http.get(url).pipe(retry(2));
  }

  validaComercioTCProxy(params: any): Observable<any> {
    let hz = this.env.hzs === "ON" && params.idVenta !== undefined && params.monto !== undefined? "/hz" : "";
    const url = `${this.aur}/sancl/partner/validacomercio/v1/comercio${hz}`;
    return this.http.post(url, params).pipe(retry(2));
  }

  getEncryptProxy(params: any): Observable<any> {

    const headers1 = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('X-Santander-Client-Id', this.ci);

    const url =  `${this.hoa}/development_environment/data_encryption/v1/encryption`;
    return this.http.post(url, params, { headers: headers1 }).pipe(retry(3));
  }


  getDecryptProxy(params: any): Observable<any> {
    const headers1 = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('X-Santander-Client-Id', this.ci);
    const url = `${this.hoa}/development_environment/data_encryption/v1/decryption`;
    return this.http.post(url, params, { headers: headers1 }).pipe(retry(3));
  }

  getDataTCVProxy(command: string): Observable<any> {
    const headers1 = new HttpHeaders()
    .set('X-Santander-Client-Id', this.ci);
  //  console.log('headers1 data:',headers1);
    const url = `${this.hoa}/customer_offer/tcv_contracting_platform/v1/data_tcv?command=${command}`;
    return this.http.get(url,{ headers: headers1 }).pipe(retry(3));
  }



}
