import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'
import { PageNotFoundComponent } from './main/components/page-not-found/page-not-found.component';
import { MainComponent } from './main/components/main.component';
import { RedireccionService } from './shared/services/redireccion.service';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: '',
    component: MainComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }),
  HttpClientModule],
  providers: [RedireccionService],
  exports: [RouterModule]
})
export class AppRoutingModule { }
