
import * as CryptoJS from 'crypto-js';
import { PlatformLocation } from '@angular/common';

export class EncryptHelper {

  constructor( private platformLocation: PlatformLocation) { }
    am = this.getAmb();
    ek2 = "10293847563789876536254325678765" ;
    iv2 =  "1676542567653565" ;
    ek3 =  "4D6251655468576D5A7134743777217A";
    iv3 =  "1028659794507124";
    ek = this.am === "L" ||  this.am === "H"? this.ek2 : this.ek3;
    iv = this.am  === "L" ||  this.am  === "H"? this.iv2 : this.iv3;

    encryptAESHex(code: string): string {
        console.log('code:', code);
        const key = CryptoJS.enc.Utf8.parse(this.ek);
        const iv = CryptoJS.enc.Utf8.parse(this.iv);
        code = CryptoJS.enc.Utf8.parse(code);
        const encodeAES = CryptoJS.AES.encrypt(code, key, { iv: iv });
        const e64 = CryptoJS.enc.Base64.parse(encodeAES.toString());
        var eHex = e64.toString(CryptoJS.enc.Hex);

        return eHex;
    }

    decryptAESHex(code: string): string {
        try {
            const key = CryptoJS.enc.Utf8.parse(this.ek);
            const iv = CryptoJS.enc.Utf8.parse(this.iv);
            const data = CryptoJS.enc.Hex.parse(code);
            const b64 = data.toString(CryptoJS.enc.Base64);
            let encodeAES = CryptoJS.AES.decrypt(b64, key, { iv: iv });
            encodeAES = encodeAES.toString(CryptoJS.enc.Utf8);
            return encodeAES;
        } catch (e) {
            return "";
        }

    }
    decryptAESHex2(code: string): string {
      try {
          const key = CryptoJS.enc.Utf8.parse(this.ek2);
          const iv = CryptoJS.enc.Utf8.parse(this.iv2);
          const data = CryptoJS.enc.Hex.parse(code);
          const b64 = data.toString(CryptoJS.enc.Base64);
          let encodeAES = CryptoJS.AES.decrypt(b64, key, { iv: iv });
          encodeAES = encodeAES.toString(CryptoJS.enc.Utf8);
          return encodeAES;
      } catch (e) {
          return "";
      }

    }

    getAmb(): string {
      let am ="L";
      //hn === "localhost" ||
      let hn = this.validarHostname();
      console.log('hn', hn);

      if(hn === "solicitudtarjeta.santander-homo.cl" || hn === "hintvtcmzwe01.cl.bsch"  || hn === "hintvtcmzwe02.cl.bsch"){
        am ="H";
      } else if (hn === "solicitudtarjeta.santander.cl" || hn === "pintvtcmzwe01.cl.bsch"  || hn === "pintvtcmzwe02.cl.bsch"){
        am ="P";
      }
      console.log('se llamo getAmb');
      console.log('am', am);
      return am;

    }


    validarHostname(): string {
      const currentUrl = this.platformLocation.href;
      const url = new URL(currentUrl);
      console.log('Current URL:', this.platformLocation.href);
      // Verificamos si el origen del href coincide con el origen de la aplicación
      if (this.coincideOrigen(url)) {
        // Si coincide, devolvemos solo el nombre del host
        return url.hostname;
      } else {
        // Si no coincide, devolvemos una cadena vacía
        return '';
      }
    }

     coincideOrigen(url: URL): boolean {
      // Obtenemos el origen de la URL actual
      const currentOrigin = `${url.protocol}//${url.hostname}${url.port ? ':' + url.port : ''}`;

      // Construimos el origen del href
      const hrefUrl = new URL(this.platformLocation.href);

      // Verificamos si el origen del href coincide con el origen de la aplicación
      return currentOrigin === `${hrefUrl.protocol}//${hrefUrl.hostname}${hrefUrl.port ? ':' + hrefUrl.port : ''}`;
    }

}
