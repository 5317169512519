import { Injectable } from '@angular/core';

import { environment } from '../environment';
import * as EnvironmentParameters from './environment-params.model';
import { EncryptHelper } from 'src/app/shared/helpers/encrypt.helper';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentParamsService {

  private env: EnvironmentParameters.EnvironmentParams;

  constructor(
    private platformLocation: PlatformLocation
  ) { }


  generarJson(am: string){

    let prd= am === "L" ||  am === "H"? false : true;
    let aur= prd?
    "22848ED06FFE3F20FAD0E4F3F8C788B334EE909A255799DF4258539E0D62CB2F6477D04124C41B4F4B86BEB3E877E9DD":
    "2661E432581C84D3B57FF6065902FFC30C0E9ABF5BCA5AAB9970E515DC8EF547777C96B274E155E71592C04D5086A66C";
    let sk= prd?
    "41769C052F8C216948B00B93978E497415939C832B9E7BBEBE61984AFC879701EB00B62E47F5438C73E1025085E5C380":
    "C7CA584BD71B1E7888B5507284C686E5645B8B490CD47AD9DB3041E4D38DB9678EAB4DDC2A93013A103251F2432F2699";
    let hoa = prd?
    "B65596757BE64D0F095D5890C214360CAA1083BEF0830B072C3899DEEBC905B735AF662DF73D93AAEF34BD0E11204500":
    "6AE92021C1F5FECA7723D99722D1F0D45EEC54D923820E2E5C7BFAE6B977EEC9E0F30B3B0807984EF4891E357656AB1B";
    let ci= prd?
     "480C61A0911AE06C78165FD2424E9739DFD8EB94224C3818BB2602C640991C000275DC3B5D59563808B73EE5FBC322F0":
     "BEB18143A4CA2B672ED51D50D7004AB80D4EED5C36F1822B96F0138B5350AE3D265705C4C51F58F79820FC0E49CBDAB0";

    let js= {};
      js["prd"]= prd;
      js["am"]= am;
      js["rtu"]= "00000000019";
      js["si"]= "WEB000000000001";
      js["ci"]= this.decryp(ci);
      js["hoa"]= this.decryp(hoa);
      js["comm"]= "";
      js["urc"]= "";
      js["cov"]= "";
      js["mon"]= "";
      js["cve"]= "";
      js["sbpo"]= "";
      js["cn"]= "";
      js["amp"]= "";
      js["sbp"]= "";
      js["com"]= "";

      //Nueva API

      js["aur"]= this.decryp(aur);
      js["eci"]= "";
      js["aci"]= "";
      js["acs"]=  "";
      js["agt"]= "";
      js["sk"]= this.decryp(sk);
      js["hzs"]= "ON";
      js["tcp"]= {
        "url_js_liv": "",
        "url_js_cap": "",
        "locale": "",
        "http": false,
        "alt_server": "",
        "url_lbliv": "",
        "alt_server_a": "",
        "url_lbac": "",
        "retry_on_timeout": true,
        "prod": prd
      };

    //----<

    return js;
  }

  decryp(a: string){
    return  new EncryptHelper(this.platformLocation).decryptAESHex(a);
  }

  getEnvVars() {
    let am = new EncryptHelper(this.platformLocation).getAmb();
   console.log('am:',am);
    let paramsJson = this.generarJson(am);
    this.env = paramsJson as EnvironmentParameters.EnvironmentParams;
    console.log('env:', this.env );

  }


  getEnvironment(): EnvironmentParameters.EnvironmentParams {
    return this.env;
  }


  generateSED() {
    const items = [];
    Object.keys(this.env).forEach(key1 => {
      const item = this.env[key1];
      if (typeof item !== 'string') {
        Object.keys(item).forEach(key2 => {
          if (key2 === 'host') {
            items.push(`s=${item[key2]}={{host}}=g`);
          } else {
            items.push(`s=${item[key2]}=${key2}=g`);
          }
        });
      } else {
        items.push(`s=${item}=${key1}=g`);
      }
    });
   // console.log(`EnvVars > host:\n`, items.join('\n'));
    return items;
  }

}
