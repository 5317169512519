export enum MixpanelEvent {
  INIT_INIT = '01-01 - inicio_inicio',
  INIT_NEXT = '01-02 - inicio_continua',
  /* New events WEB */
  INIT_ERR = '01-03 - inicio_valida_comercio_error_web',
  //INIT_ACCEPT_DISC = '01-04 - inicio_acepta_autorizacion_web',
  //INIT_SHOW_REQ = '01-05 - inicio_muestra_requerimientos_web',
  /* */
  LOGIN_INIT = '02-01 - login_inicio',
  LOGIN_NEXT = '02-02 - login_continua',
  LOGIN_ERR_CRED = '02-03 - login_err_credenciales',
  LOGIN_ERR_CONN = '02-04 - login_err_conexion',
  LOGIN_ERR_RESP = '02-05 - login_err_respuesta',
  /* New events WEB */
  //VALIDATECI_INIT = '02-06 - validateci_inicio',
  //VALIDATECI_API = '02-07 - validateci_api_inicio',
  //VALIDATECI_API_ERR = '02-08 - validateci_api_error',
  //VALIDATECI_RECAPTCHA = '02-09 - validateci_recaptcha_inicia',
  //VALIDATECI_RECAPTCHA_ERR = '02-10 - validateci_recaptcha_error',
  //VALIDATECI_NEXT = '02-11 - validateci_acepta_valida',
  //VALIDATECI_NEXT_TOC = '02-12 - validateci_siguiente',
  //VALIDATECI_VALIDA_CLIENTE = '02-13 - validateci_valida_cliente_inicio',
  //VALIDATECI_VALIDA_CLIENTE_ERR = '02-14 - validateci_valida_cliente_inicio',
  /* */

  MENU_EXIT = '03-00 - menu_salir',
  MENU_INIT = '03-01 - menu_inicio',
  MENU_REQUEST = '03-02 - menu_solicitar',
  MENU_PAY = '03-03 - menu_pagar',
  MENU_CANCEL = '03-04 - menu_cancelar',
  MENU_RESUME = '03-05 - menu_caja',

  DISCLAIMER_EXIT = '04-00 - autorizacion_retrocede_etapa',
  DISCLAIMER_INIT = '04-01 - autorizacion_inicio',
  DISCLAIMER_TYC = '04-02 - autorizacion_check',
  DISCLAIMER_ACCEPT = '04-03 - autorizacion_acepta',
  DISCLAIMER_CANCEL = '04-04 - autorizacion_rechazar',
  DISCLAIMER_UPLOAD = '04-05 - autorizacion_subir_doc',
  DISCLAIMER_UPLOAD_ERR = '04-06 - autorizacion_subir_error',
  /* New events v2 */
  INIT_ACCEPT_DISC = '04-07 - autorizacion_acepta_web',
  INIT_CANCEL_DISC = '04-08 - autorización_cancela_web',
  INIT_SHOW_REQ = '04-09 - autorizacion_requisitos_web',
  INIT_NEXT_WEB = '04-10 - autorizacion_acepta_comenzar_continuar',
  INIT_BROWSER_ERR = '04-11 - autorizacion_browser_no_compatible',
  /* */

  FINGERPRINT_EXIT = '05-00 - vdactilar_retrocede_etapa',
  FINGERPRINT_INIT = '05-01 - vdactilar_inicio',
  FINGERPRINT_TOC_INIT = '05-02 - vdactilar_toc_inicio',
  FINGERPRINT_TOC_RESPONSE = '05-03 - vdactilar_toc_respuesta',
  FINGERPRINT_TOC_RESULT = '05-04 - vdactilar_toc_resultado',
  FINGERPRINT_NEXT = '05-05 - vdactilar_continua',
  /* new events v2 */
  VALIDATECI_INGRESO_CI = '05-06 - validateci_ingreso_ci',
  VALIDATECI_INGRESO_SERIE = '05-07 - validateci_ingreso_serie',
  VALIDATECI_INIT = '05-08 - validateci_inicio',
  VALIDATECI_API = '05-09 - validateci_api_inicio',
  VALIDATECI_API_ERR = '05-10 - validateci_api_error',
  VALIDATECI_RECAPTCHA = '05-11 - validateci_recaptcha_inicia',
  VALIDATECI_RECAPTCHA_ERR = '05-12 - validateci_recaptcha_error',
  VALIDATECI_NEXT = '05-13 - validateci_acepta_valida',
  VALIDATECI_NEXT_TOC = '05-14 - validateci_siguiente',
  VALIDATECI_VALIDA_CLIENTE = '05-15 - validateci_consulta_clientetc_inicio',
  VALIDATECI_VALIDA_CLIENTE_OK = '05-16 - validateci_consulta_clientetc_ok',
  VALIDATECI_VALIDA_CLIENTE_KO = '05-17 - validateci_consulta_clientetc_nok',
  VALIDATECI_VALIDA_CLIENTE_ERR = '05-18 - validateci_consulta_clientetc_error',
  VALIDATECI_VALIDA_GIF = '05-19 - validateci_continuar_gif',
  /* */

  FACIAL_EXIT = '06-00 - facial_retrocede_etapa',
  FACIAL_INIT = '06-01 - facial_inicio',
  FACIAL_NEXT_TAKE = '06-02 - facial_iniciafotos',
  FACIAL_TAKE_EXIT = '06-03 - facial_retrocede',
  FACIAL_TAKE_INIT = '06-04 - facial_iniciatoma',
  FACIAL_TAKE_SELFIE = '06-05 - facial_captura_rostro',
  FACIAL_TAKE_DOC_A = '06-06 - facial_captura_cifrontal',
  FACIAL_TAKE_DOC_B = '06-07 - facial_captura_cireverso',
  FACIAL_NEXT = '06-08 - facial_continua',
  FACIAL_NEXT_ERR = '06-09 - facial_continua_error',
  /* New events WEB */
  FACIAL_SM_INIT = '06-10 - facial_session_manager_inicio',
  FACIAL_SM_ERR = '06-11 - facial_session_manager_error',
  /* New events WEB v2 */
  FACIAL_TAKE_SELFIE_OK = '06-12 - facial_captura_rostro_ok',
  FACIAL_TAKE_SELFIE_ERR = '06-13 - facial_captura_rostro_error',
  /* New events WEB v3 */
  FACIAL_TAKE_DOC_A_OK = '06-14 - facial_captura_cifrontal_ok',
  FACIAL_TAKE_DOC_A_ERR = '06-15 - facial_captura_cifrontal_error',
  FACIAL_TAKE_DOC_B_OK = '06-16 - facial_captura_cireverso_ok',
  FACIAL_TAKE_DOC_B_ERR = '06-17 - facial_captura_cireverso_error',
  FACIAL_NEXT_OK = '06-18 - facial_continua_ok',
  /* */

  /* New events WEB v3 */
  CLIENT_INIT = '07-00 - cliente_inicio',
  /* */
  CLIENT_FIND = '07-01 - cliente_consultar',
  CLIENT_GET = '07-02 - cliente_capturar',
  CLIENT_RESUME = '07-03 - cliente_retoma_estado',
  CLIENT_ERR = '07-04 - cliente_error',
  CLIENT_REGISTER = '07-05 - cliente_alta',
  CLIENT_REGISTER_ERR = '07-06 - cliente_alta_error',
  /* New events WEB */
  CLIENT_GET_QUESTIONS_INIT = '07-07 - cliente_preguntas_equifax',
  CLIENT_GET_QUESTIONS_ERR = '07-08 - cliente_preguntas_equifax_error',
  CLIENT_GET_ANSWER_INIT = '07-09 - cliente_respuesta_equifax',
  CLIENT_GET_ANSWER_ERR = '07-10 - cliente_respuesta_equifax_error',
  /* New events WEB v3 */
  CLIENT_RESUME_ERR = '07-07 - cliente_retoma_estado_error',
  CLIENT_FIND_ERR = '07-08 - cliente_consultar_error',
  /* */

  DATA_EXIT = '08-00 - datos_retrocede_etapa',
  DATA_INIT = '08-01 - datos_inicio',
  DATA_MARRIED = '08-02 - datos_estadocivil',
  DATA_REGION = '08-03 - datos_aperturaregion',
  DATA_REGION_SELECTED = '08-04 - datos_eleccionregion',
  DATA_COMUNA = '08-05 - datos_aperturacomuna',
  DATA_COMUNA_SELECTED = '08-06 - datos_eleccioncomuna',
  DATA_CITY = '08-07 - datos_aperturaciudad',
  DATA_CITY_SELECTED = '08-08 - datos_eleccionciudad',
  DATA_STUDY = '08-09 - datos_aperturaestudio',
  DATA_STUDY_SELECTED = '08-10 - datos_eleccionestudio',
  DATA_OTHER_ACTIVITY = '08-11 - datos_eleccionotraactividad',
  DATA_OTHER_ACTIVITY_CHECK = '08-12 - datos_eleccionotraactividad_check',
  DATA_NEXT = '08-13 - datos_continua',
  DATA_SAVE = '08-14 - datos_guardar',
  DATA_SAVE_ERROR = '08-15 - datos_guardar_error',
  DATA_COUNTRY = '08-16 - datos_nacionalidad',

  SMS_EXIT = '09-00 - smscel_retrocede_etapa',
  SMS_INIT = '09-01 - smscel_inicio',
  SMS_SEND = '09-02 - smscel_envio',
  SMS_SEND_ERR = '09-03 - smscel_envio_error',
  SMS_WAIT = '09-04 - smscel_esperacodigo',
  SMS_RESEND = '09-05 - smscel_reenviar_codigo',
  SMS_ENTER = '09-06 - smscel_ingresocodigo_cont',
  SMS_VALIDATE_ERR = '09-07 - smscel_validar_error',
  SMS_NEXT = '09-08 - smscel_continua',

  OFFER_INIT = '10-01 - ofertatc_inicio_oferta',
  OFFER_TC = '10-02 - ofertatc_ofertatarjetas',
  OFFER_TC_ERR = '10-03 - ofertatc_ofertatarjetas_error',
  OFFER_AMOUNT_ACCEPT = '10-04 - ofertatc_cupomenortc_acepta',
  OFFER_AMOUNT_CANCEL = '10-05 - ofertatc_cupomenortc_rechaza',
  OFFER_TC_SELECTED = '10-06 - ofertatc_eleccionofertatc',
  OFFER_ACCEPT = '10-07 - ofertatc_acepta',
  OFFER_CANCEL = '10-08 - ofertatc_rechaza',
  OFFER_PAY_DATE = '10-09 - ofertatc_fecha_pago',
  OFFER_TYC = '10-10 - ofertatc_heleido',
  OFFER_TC_SAVE = '10-11 - ofertatc_guardar',
  OFFER_TC_SAVE_ERR = '10-12 - ofertatc_guardar_error',
  /* New events WEB v3 */
  OFFER_TC_OFFER_DECLINED = '10-13 - ofertatc_gerara_oferta_decline',
  OFFER_TC_FAM = '10-14 - ofertatc_genera_oferta_fam',
  OFFER_TC_FAM_ERR = '10-15 - ofertatc_genera_oferta_fam_error',
  OFFER_TC_PROD = '10-16 - ofertatc_genera_oferta_prod',
  OFFER_TC_PROD_ERR = '10-17 - ofertatc_genera_oferta_prod_error',
  OFFER_TC_OFFERMP = '10-18 - ofertatc_guarda_ofertamp',
  OFFER_TC_OFFERMP_ERR = '10-19 - ofertatc_guarda_ofertamp_error',
  OFFER_TC_CHANGE_STATE = '10-20 - ofertatc_cambio_estado_inicia',
  OFFER_TC_CHANGE_STATE_ERR = '10-21 - ofertatc_cambio_estado_error',
  OFFER_TC_PERSO_INIT = '10-22 - ofertatc_personalizacion_inicia',
  OFFER_TC_PERSO_ERR = '10-23 - ofertatc_personalizacion_error',
  OFFER_TC_ALTAMP_INIT = '10-24 - ofertatc_altamp_inicia',
  OFFER_TC_ALTAMP_ERR = '10-25 - ofertatc_altamp_error',
  OFFER_TC_PERS_CHANGE = '10-26 - ofertatc_personalizacion_cambioestado',
  OFFER_TC_PERS_CHANGE_ERR = '10-27 - ofertatc_personalizacion_cambioestado_error',
  OFFER_RETRACTO_CONFIRM = '11-28 - ofertatc_acepta_retracto',
  /* */

  CONTRACT_INIT = '11-01 - contrato_inicio',
  CONTRACT_GET_DOCUMENT = '11-02 - contrato_recuperar_documentos',
  CONTRACT_GET_DOCUMENT_ERR = '11-03 - contrato_recuperar_documentos_error',
  CONTRACT_TYC = '11-04 - contrato_heleido',
  CONTRACT_ACCEPT = '11-05 - contrato_aceptar',
  CONTRACT_CANCEL = '11-06 - contrato_rechazar',
  CONTRACT_TOC_INIT = '11-07 - contrato_toc_inicio',
  CONTRACT_TOC_RESPONSE = '11-08 - contrato_toc_respuesta',
  CONTRACT_TOC_RESULT = '11-09 - contrato_toc_resultado',
  CONTRACT_SIGN_A = '11-10 - contrato_firma_3',
  CONTRACT_SIGN_B = '11-11 - contrato_firma_20',
  CONTRACT_ANNEX_SIGN = '11-12 - contrato_firma_anexo',
  CONTRACT_UPLOAD = '11-13 - contrato_subir',
  CONTRACT_ANNEX_UPLOAD = '11-14 - contrato_anexo_subir',
  CONTRACT_SAVE_STATE = '11-15 - contrato_guardar_estado',
  CONTRACT_NEXT = '11-16 - contrato_continua',
  /* New events WEB v3 */
  CONTRACT_INSTRUCTIONS_TOC = '11-17 - contrato_acepta_intrucciones',
  CONTRACT_SESSION_MANG_INIT = '11-18 - contrato_session_manager_inicio',
  CONTRACT_SESSION_MANG_ERR = '11-19 - contrato_session_manager_error',
  CONTRACT_LIVENESS_INIT = '11-20 - contrato_liveness_inicia',
  CONTRACT_LIVENESS_ERR = '11-21 - contrato_liveness_error',
  CONTRACT_SIGN_INIT = '11-22 - contrato_firma_inicia',
  CONTRACT_SIGN_ERR = '11-23 - contrato_firma_error',
  CONTRACT_CHANGE_STATE_INIT = '11-24 - contrato_cambio_estado',
  CONTRACT_CHANGE_STATE_ERR = '11-25 - contrato_cambio_estado_error',
  /**/

  CURSE_INIT = '12-01 - cursetc_inicio',
  CURSE_REGISTER = '12-02 - cursetc_ok',
  CURSE_REGISTER_ERROR = '12-03 - cursetc_ok_error',
  CURSE_READY = '12-04 - cursetc_finalizar',
  CURSE_END = '12-05 - cursetc_end',

  SIMULATE_EXIT = '13-00 - simpago_retrocede_etapa',
  SIMULATE_INIT = '13-01 - simpago_inicio',
  SIMULATE_RESULT = '13-02 - simpago_resultado_simulacion',
  SIMULATE_RESULT_ERR = '13-03 - simpago_resultado_simulacion_err',
  SIMULATE_CONFIRM = '13-04 - simpago_acepta_simulacion',
  SIMULATE_CONFIRM_ERR = '13-05 - simpago_acepta_simulacion_error',
  SIMULATE_PAY_INIT = '13-06 - simpago_inicia_flujo_pago',
  SIMULATE_VALIDA_CLIENTE_INIT = '13-07 - simpago_valida_cliente_inicia',
  SIMULATE_VALIDA_CLIENTE_ERR = '13-08 - simpago_valida_cliente_error',
  SIMULATE_OFERTA_INIT = '13-09 - simpago_oferta_inicia',
  SIMULATE_OFERTA_ERR = '13-10 - simpago_oferta_error',


  PAYSMS_EXIT = '14-00 - smspago_retrocede_etapa',
  PAYSMS_INIT = '14-01 - smspago_inicio',
  PAYSMS_GET_PHONE = '14-02 - smspago_recuperar_telefono',
  PAYSMS_GET_PHONE_ERR = '14-03 - smspago_recuperar_telefono_error',
  PAYSMS_SEND = '14-04 - smspago_enviar',
  PAYSMS_WAIT = '14-05 - smspago_espera_codigo',
  PAYSMS_RESEND = '14-06 - smspago_reenviar',
  PAYSMS_RESEND_ERR = '14-07 - smspago_reenviar_error',
  PAYSMS_ENTER = '14-08 - smspago_ingresa_codigo',
  PAYSMS_NEXT = '14-09 - smspago_continua',
  PAYSMS_NEXT_ERR = '14-10 - smspago_continua_error',

  BUY_EXIT = '15-00 - cursepago_retrocede_etapa',
  BUY_INIT = '15-01 - cursepago_inicio',
  BUY_TICKET = '15-02 - cursepago_ingresa_boleta',
  BUY_END = '15-03 - cursepago_finaliza_pago',
  BUY_END_ERR = '15-04 - cursepago_finalizapago_error',

  CANCEL_EXIT = '16-00 - anulacion_retrocede_etapa',
  CANCEL_INIT = '16-01 - anulacion_inicio',
  CANCEL_OK = '16-02 - anulacion_okanulacion',
  CANCEL_OK_ERR = '16-03 - anulacion_okanulacion_err',

  CASHBOK_EXIT = '17-00 - caja_retrocede_etapa',
  CASHBOK_INIT = '17-01 - caja_inicio',
  CASHBOK_GET_DATA = '17-02 - caja_buscar_datos',
  CASHBOK_GET_DATA_ERR = '17-03 - caja_buscar_datos_error',
  CASHBOK_DATA_DISPLAY = '17-04 - caja_mostrar_datos',
  CASHBOK_FINISH = '17-05 - caja_finaliza_revision'
}
