import { Component, OnInit, ViewChild, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AddInViewLoadingDirective, AddFullLoadingDirective, LoadingService } from '@santander/loading';
import { ModalsService, TypeModal, ModalDetail, ModalResponse } from '@santander/modals';
import { GeneralService } from 'src/app/shared/services/general/general.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { EncryptHelper } from 'src/app/shared/helpers/encrypt.helper';
import { PlatformLocation } from '@angular/common';
import { EnvironmentParamsService } from '../../../environments/services/environment-params.service';
import { MixpanelService } from 'src/app/shared/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'src/app/shared/interfaces/mixpanel-events.enum';
import { TealiumUrl } from 'src/app/shared/interfaces/tealium.num';
import { TealiumTrackService } from 'src/app/shared/services/tealium/tealium-track.service';
import { TocService } from 'src/app/shared/services/habilitadores/toc.service';
import {  Observable, forkJoin } from 'rxjs';
import { ReCaptchaV3Service  } from "ng-recaptcha";
import { RedireccionService } from '../../shared/services/redireccion.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  private tokenCargadoSubject = new BehaviorSubject<boolean>(false);
  private clicComenzarSubject = new BehaviorSubject<boolean>(false);
  tokenCargado$ = this.tokenCargadoSubject.asObservable();

  @ViewChild(AddInViewLoadingDirective, { static: true }) addInView: AddInViewLoadingDirective;
  @ViewChild(AddFullLoadingDirective, { static: true }) addFull: AddFullLoadingDirective;
  env: any;
  enableNext: boolean = false;
  qParams: any;;
  storeName: string = '';
  checked: boolean = false;
  step4: string = "Firma contrato";
  isPayment: boolean = false;
  grecaptcha: any;
  reloadCount: number = 0;
  tokenCargado: boolean = false;
  clicComenzar: boolean = false;



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loading: LoadingService,
    private general: GeneralService,
    private readonly modal: ModalsService,
    private storage: StorageService,
    private envParams: EnvironmentParamsService,
    private mixpanel: MixpanelService,
    private location: Location,
    private tealium: TealiumTrackService,
    private toc: TocService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private platformLocation: PlatformLocation,
    private redireccionService: RedireccionService,
    private cdr: ChangeDetectorRef
  ) {
    this.env = this.envParams.getEnvironment() as { cov: string, mon: string, cve: string, comm: string, urc: string,  si: string};

    this.tokenCargado$.subscribe(tokenCargado => {
      console.log('tokenCargado:',tokenCargado);
      console.log('clicComenzar:',this.clicComenzar);
      if (tokenCargado && this.clicComenzar) {
        this.router.navigateByUrl('validatuidentidad');
      }

    });

  }

  @ViewChild('recaptchaContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('recaptcha', { static: false }) recaptcha: any;


  resolvedCaptchaInvisible(): void {
    const observable = this.recaptchaV3Service?.execute('importantAction');
    if (observable) {
      observable.subscribe((token: string) => {
        console.log('Token generado:', token); // Agrega este log para verificar el token
        this.storage.removeAll();
        this.capturarPaginaS();
        let command = this.convertirStringBase64("SANTANDER");
        console.log('Comando generado:', command); // Agrega este log para verificar el comando
        this.storage.setItem('token-access_token', '');
        if (token && command) {
          this.TokenTCVRecaptcha(token, command);
        } else {
          console.log('resolvedCaptchaInvisible error token && command');
          this.handleTokenError();
        }

      }, error => {
        console.log('error en resolvedCaptchaInvisible ---->');
        this.resetRecaptcha()

      });
    } else {
      console.log('error en resolvedCaptchaInvisible');
      this.handleTokenError();
    }
  }

  ngOnInit() {
    this.loading.showFullLoading();
    this.getComercio();
  }

  ngOnDestroy(): void {
    this.destroyRecaptcha();
  }

  resetRecaptcha() {
    console.log('resetRecaptcha');
    this.destroyRecaptcha();
    setTimeout(() => {
      this.cdr.detectChanges();
      this.cliccBotonCaptchaInvisible();
    });
  }


  destroyRecaptcha() {
    if (this.recaptcha && this.recaptcha.destroy) {
      console.log('destroyRecaptcha');
      this.recaptcha.destroy();
    }
  }

  capturarPaginaS(){
    let pag2 = 'SANTANDER.CL';
    this.storage.setItem('storeName', pag2);
    this.storage.setItem('storeStatus', '3');
    this.storeName = pag2;
  }

  TokenTCVRecaptcha(token, command) {
  //  console.log('Llamada a TokenTCVRecaptcha con token:', token, 'y command:', command);
    console.log('Llamada a TokenTCVRecaptcha con token');

    if (token === undefined || command === undefined) {
      console.error('Error en TokenTCVRecaptcha: token o command no definidos');
      this.handleTokenError();
      return;
    }

    this.toc.getTokenTCVRecaptcha(token, command).subscribe(
      (res) => {
        this.handleTokenResponse(res);
      },
      (error) => {
        console.error('Error en TokenTCVRecaptcha:', error);
        this.reloadCount = this.reloadCount + 1;
        console.log('reloadCount:', this.reloadCount);
        if(error?.statusText === "Unknown Error" && this.reloadCount < 2){
          console.log('Error Unknown Error');
          this.resetRecaptcha();
        }
        else this.handleTokenError();
      }
    );
  }

  handleTokenResponse(res: any): void {
    if (res.METADATA?.STATUS === '0' && res.METADATA?.DESCRIPCION === 'OK') {
      const token = res.DATA?.access_token;
      if (token) {
        this.storeToken(token);
        console.log('SERVICIO token_recapcha_TCV', token);
        this.storage.setItem('urlCallback', "D8DC904B71AA7228D474AC36C8938CB4E2C612B4CEA972B10488F1C040FEA401");
      } else {
        console.error('Error en TokenTCVRecaptcha: token no válido');
        this.handleTokenError();
      }
    } else {
      console.error('Error en TokenTCVRecaptcha: Respuesta de token no exitosa');
      this.handleTokenError();
    }
  }

  handleTokenError(): void {
    this.loading.closeFullLoading();
    this.destroyRecaptcha();
    this.retryError('20');
  }

  storeToken(token: string): void {
    this.storage.setItem('token-access_token', token);
    this.storage.setItem('token-api', token);

    this.tokenCargado= true;
    this.tokenCargadoSubject.next(true); // Notifica el cambio
  }


  getComercio(){
    this.mixpanel.init();

    this.route.queryParams.subscribe(params => {
      this.qParams = params;
    });

      if (this.qParams.token !== undefined) this.storage.removeAll();

      if (this.qParams.campaign === undefined) {
        this.storage.setItem('campaign', '');
      } else {
        this.storage.setItem('campaign', this.qParams.campaign);
      }

      this.env.cov = "";
      this.env.mon = "";
      this.env.cve= this.qParams.codigoVenta !== undefined? this.qParams.codigoVenta: "";
      if (this.qParams.token !== undefined) {

        this.tokenCargado= true;
        this.tokenCargadoSubject.next(true);

        // Guarda params
        this.storage.setItem('token-access_token', this.qParams.token);
        this.storage.setItem('token-api', this.qParams.token);
        this.storage.setItem('urlCallback', this.qParams.urlCallback);

        console.log('urlCallback',this.storage.getItem('urlCallback'));

        const monto = this.qParams.monto;
        const codigoVenta = this.qParams.codigoVenta;

        if (monto && codigoVenta && monto !== undefined && codigoVenta !== undefined) {
          this.isPayment = true;
          this.getDecryp3(monto,codigoVenta);
        }

        const commerce = this.qParams.comercio;
        const urlCallback = this.qParams.urlCallback;

        if (commerce && urlCallback) {
          this.getDecrypt2(commerce, urlCallback);
        }

      } else {

        if(this.storage.getItem('token-access_token'))  this.storage.removeItem('token-access_token');
        if(this.storage.getItem('token-api')) this.storage.removeItem('token-api');
        if(this.storage.getItem('urlCallback')) this.storage.removeItem('urlCallback');

        // se asume que viene por santander
        this.env.comm=  '0097036000K';
        this.env.urc = 'https://www.santander.cl';
        this.iniciarMixpanel();
        this.iniciarTealium();
        this.cliccBotonCaptchaInvisible();
        this.tealium.trackEvent(TealiumUrl.TEA_URL_WELCOME, '', '', '', '', '');

        this.loading.closeFullLoading();

      }



  }


  iniciarMixpanel(){
    this.mixpanel.deletePerson();
    this.mixpanel.track(MixpanelEvent.INIT_INIT, {});
  }

  iniciarTealium(){
    this.tealium.init('');
  }

  enableButton(event) {
    this.tealium.trackEventButton('Autorizo la obtención de datos');
    if (event.checked) {
      this.mixpanel.track(MixpanelEvent.INIT_ACCEPT_DISC, {});
      this.enableNext = true;
    } else {
      this.mixpanel.track(MixpanelEvent.INIT_CANCEL_DISC);
      this.enableNext = false;
    }
  }

  goToToc() {
    if (this.check_browser_so() == false) {
      this.mixpanel.track(MixpanelEvent.INIT_BROWSER_ERR);
      this.tealium.trackEvent(TealiumUrl.TEA_URL_ERROR, '', '', '200', 'Error browser', 'Cliente con navegador incompatible');
      this.modal.openModal({
        type: TypeModal.WARN,
        buttons: 1,
        title: 'Lo sentimos',
        body: "Para mejorar tu experiencia, asegúrate de usar un navegador compatible. <br><ul><li>Para dispositivos Android: Chrome</li><li>Para dispositivos iOS (iPhone /iPad): Safari.</li></ul>",
        textButtons: ['ACEPTAR'],
        option: 1,
        closeable: false
      }).subscribe(res => {
        if (res.first === true) this.getDecryptEncryptUrlCallback('20');
      });
    //  this.redirectTresSeg('20');
    }
    else {

      this.clicComenzar= true;
      this.clicComenzarSubject.next(true);

      this.tealium.trackEventButton('COMENZAR');
      this.mixpanel.track(MixpanelEvent.INIT_NEXT_WEB, {});
      this.loading.showFullLoading();
     if(this.tokenCargado) this.router.navigateByUrl('validatuidentidad');
    }
  }

  validaComercioTC(rutComercio: string, rutSucursal: string) {
    let monto = '';
    let idVenta = '';
    if (this.qParams.monto !== undefined && this.qParams.codigoVenta !== undefined) {
      monto = this.qParams.monto;
      idVenta = this.qParams.codigoVenta;
      this.validaComercioTC2(rutComercio, rutSucursal, idVenta, monto);
    } else {
        this.validaComercioTC2(rutComercio, rutSucursal, "", "");
    }
  }

  validaComercioTC2(rutComercio, rutSucursal, idVenta, monto){
    this.general.validaComercioTC(rutComercio, rutSucursal, idVenta, monto).subscribe(res => {
        if (res.Resultado === "OK") {
          this.loading.closeFullLoading();
          this.storeName = res.NombreSucursal;
          this.storage.setItem('storeName', res.NombreSucursal);
          this.storage.setItem('storeStatus', res.Estado);
          if (res.Estado !== '3') {
            this.step4 = "Firma contrato y anexo";
          }
          if (this.isPayment) {
            this.router.navigateByUrl('payment');
          }
        }
        else {
          this.mixpanel.track(MixpanelEvent.INIT_ERR, {
            HTTPCode: '200',
            State: 500,
            Extra: res.WSError.ErrorDesc
          });
          this.loading.closeFullLoading();
          this.tealium.trackEvent(TealiumUrl.TEA_URL_ERROR, '', 'validaComercioTC', '200', res.WSError.ErrorDesc, 'No es posible realizar tu solicitud.');
          this.retryError('20', 'Lo sentimos',  'No es posible realizar tu solicitud.','FINALIZAR');
        }
    }, error => {
      this.mixpanel.track(MixpanelEvent.INIT_ERR, {
        HTTPCode: 500,
        State: 500,
        Extra:  error.message || error.toString()
      });
      console.log('error', error);
      this.loading.closeFullLoading();
      this.tealium.trackEvent(TealiumUrl.TEA_URL_ERROR, '', 'validaComercioTC', '200', 'Error validaComercioTC', 'No es posible realizar tu solicitud.');
      this.retryError('20', 'Lo sentimos',  'No es posible realizar tu solicitud.','FINALIZAR');
    });
  }

  showReq() {
    this.tealium.trackEventButton('Ver requisitos');
    this.mixpanel.track(MixpanelEvent.INIT_SHOW_REQ, {});
    const body = "<ul><li>Persona natural mayor de 21 años</li><li>Cédula de identidad vigente</li><li>Nacionalidad Chilena o Extranjero con residencia definitiva (mínima de 1 año)</li><li>Renta mínima liquida de $400.000 y al menos 3 últimas cotizaciones previsionales</li><li>No tener vigente Tarjeta de Crédito Santander</li><li>Tener celular y correo electrónico operativo</li><li>Cumplir con los niveles de aprobación según evaluación comercial conforme a políticas del Banco</li></ul><br>";
    this.modal.openModal({
      type: TypeModal.INFO,
      buttons: 1,
      title: 'Requisitos',
      body: body,
      textButtons: ['Aceptar'],
      option: 1,
      closeable: false
    });
  }

  showDisclaimer() {
    let body = "<div fxLayout='row'><p>Para continuar el proceso en línea necesitamos que nos autorices a completar tus datos personales, laborales y económicos.</p>" +
      "<p>Autorizo a Banco Santander Chile para solicitar y obtener los siguientes datos personales:</p>" +
      "<p>Para los efectos de la Ley 19.628 Sobre Protección de Datos de Carácter Personal, el Cliente autoriza al Banco a compartir exclusivamente con terceros con quienes suscriba alianzas o acuerdos comerciales, ciertos datos personales como el número de Rut, domicilio, actividad, correo electrónico, o información relacionada a productos contratados con el Banco que no se encuentre sujeta a secreto bancario, cuando ello se realice con el propósito de facilitar el desarrollo, materialización y/o implementación de acciones comerciales.</p>" +
      "<p>Para los efectos de la Ley 19.799 y la obtención sin costo de los mecanismos de firma electrónica " +
      "con los cuales el cliente suscribirá los documentos electrónicos fundante de su relación contractual, el " +
      "cliente autoriza al Banco a compartir con la Autoridad Certificadora, CA o Prestador de Servicios de " +
      "Certificación TOC S.A. los antecedentes personales necesarios para cumplir con las obligaciones previa de " +
      "(i) Autoridad de Registro o de comprobación y verificación fehaciente de la identidad del cliente/signatario " +
      "y la posterior de (ii) emisión del certificado digital o software de firmado, el cual el cliente activará " +
      "con la clave privada (Por ejemplo huella dactilar o rostro) que siempre mantendrá bajo su exclusivo control y responsabilidad.</p>" +
      "<p><b>Datos personales:</b> Nombre y apellidos: Cédula nacional de identidad y estado de ésta. Fecha Nacimiento; Estado Civil; Nacionalidad; Profesión u oficio; Número de teléfono celular; Correo Electrónico.</p>" +
      "<p><b>Actividad económica:</b> Información tributaria; Actividad Económica; Fecha de inicio de Actividades; Cualquier información sobre mis bienes muebles, inmuebles, mis obligaciones y deudas registradas en el sistema financiero e Información financiera, de giro y/o de cualquier otra índole de las sociedades en las que participo en calidad de socio o accionista.</p>" +
      "<p><b>Cotizaciones previsionales:</b> Por este acto, autorizo a mi AFP, a entregar por intermedio de Previred, mis últimos períodos de cotizaciones previsionales al Banco, con el fin de ser consideradas como antecedente a esta solicitud comercial, dando así cumplimiento al artículo 4° de la ley N° 19.628 sobre Protección de la Vida Privada.</p>" +
      "<p>Estos datos serán obtenidos a través de Equifax, Previred y TOC S.A.</p>" +
      "<p>Autorizo obtener datos personales, y a comprobar y verificar la identidad y posterior emisión del certificado digital o software de firmado.</p></div><br>";

    this.tealium.trackEventButton('Autorizo la obtención de datos');

    this.modal.openModal({
      type: TypeModal.INFO,
      buttons: 2,
      title: 'Autorización',
      body: body,
      textButtons: ['Aceptar', 'Cancelar'],
      option: 1,
      closeable: false
    }).subscribe(res => {
      if (res.first === true) { this.checked = true; } else { this.checked = false; this.mixpanel.track(MixpanelEvent.INIT_CANCEL_DISC); }
      this.checked ? this.enableNext = true : this.enableNext = false;
    });

  }

  showError(type: TypeModal, title, body, nameButton: any[]): Observable<any> {
    return this.modal.openModal({
      type: type,
      buttons: 1,
      title: title,
      body: body,
      textButtons: nameButton,
      option: 1,
      closeable: false
    });
  }


  check_browser_so() {
    let userAgent = navigator.userAgent;
    if ((this.check_browser(userAgent) == "chrome") && (this.check_so(userAgent) == "Android")) {
      return true;
    }
    else {
      if ((this.check_browser(userAgent) == "safari") && (this.check_so(userAgent) == "iOS")) {
        return true;
      }
      else {
        return false;
      }
    }

  }

  check_browser(userAgent: string): string {
    if (userAgent.indexOf('Chrome') > -1) {
      return 'chrome';
    } else if (userAgent.indexOf('Firefox') > -1) {
      return 'firefox';
    } else if (userAgent.indexOf('Safari') > -1) {
      return 'safari';
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      return 'opera'; // Corregido: devuelve 'opera' cuando detecta Opera
    } else if (userAgent.indexOf('Edg') > -1) {
      return 'edge'; // Corregido: devuelve 'edge' cuando detecta Edge
    } else {
      return 'Desconocido';
    }
}

check_so(userAgent: string) {
  var userAgent = userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return "Android";
  } else if (/iPad|iPhone|iPod|Mac/.test(userAgent)) {
    return "iOS";
  } else if (/Windows NT|Windows NT 10.0/.test(userAgent)) {
    return "Windows"; // Se ajusta la condición para detectar Windows NT y Windows NT 10.0
  } else {
    return "No es mobile";
  }
}


  getDecrypt2(commerce: string, urlCallback: string){
      forkJoin([
        this.general.getDecrypt(commerce),
        this.general.getDecrypt(urlCallback)
      ]).subscribe(([commerceData, urlCallbackData]) => {
        if(commerceData.METADATA?.STATUS === '0' && commerceData.METADATA?.DESCRIPCION === 'OK' &&
        urlCallbackData.METADATA?.STATUS === '0' && urlCallbackData.METADATA?.DESCRIPCION === 'OK'){

            this.env.comm= commerceData.DATA?.decrypt;
            this.env.urc= urlCallbackData.DATA?.decrypt;

            this.iniciarMixpanel();
            this.iniciarTealium();

            if (this.qParams.monto === undefined || this.qParams.codigoVenta === undefined) this.tealium.trackEvent(TealiumUrl.TEA_URL_WELCOME, '', '', '', '', '');

            const rutComercio = commerceData.DATA?.decrypt;
            const rutSucursal = this.env.si;
            if (rutComercio && rutSucursal) {
               this.validaComercioTC(rutComercio, rutSucursal);
            }

        } else  {
            console.error('Error al desencriptar commerce y urlCallback');
            this.handleDecryptionError();
          }
      }, error => {
        console.error('Error al desencriptar commerce y urlCallback2:', error);
        this.handleDecryptionError();
      });
  }

  getDecryp3(codigoVenta: string, monto: string){
    forkJoin([
      this.general.getDecrypt(codigoVenta),
      this.general.getDecrypt(monto)
    ]).subscribe(([codigoVentaData, montoData]) => {
      if(codigoVentaData.METADATA?.STATUS === '0' && codigoVentaData.METADATA?.DESCRIPCION === 'OK' &&
      montoData.METADATA?.STATUS === '0' && montoData.METADATA?.DESCRIPCION === 'OK'){

          this.env.cov= codigoVentaData.DATA?.decrypt;
          this.env.mon= montoData.DATA?.decrypt;

      } else  {
          console.log('error Decriptar codigoVenta y monto 2');
          this.handleDecryptionError();
        }
    }, error => {
      console.log('error Decriptar codigoVenta y monto',error);
      this.handleDecryptionError();
    });
  }

  handleDecryptionError(): void {
    this.loading.closeFullLoading();
    this.location.back();
    this.getDecryptEncryptUrlCallback('20');
  }

  getDecryptEncryptUrlCallback(code){
    let url = this.env.urc;
    this.loading.showFullLoading();

    if (url !== undefined && url !== null && code !== undefined && code !== null){
      const observable = this.general.getEncrypt(code);
      if (observable) {
        observable.subscribe(res => {
          console.log('urc:',url);
          console.log('status:',res.DATA?.encrypt);
        if(res.METADATA?.STATUS === '0' && res.METADATA?.DESCRIPCION === 'OK')
        this.redireccionService.actualizarURL(url,res.DATA?.encrypt);
        else {
          this.loading.closeFullLoading();
          console.log('error Encriptar cod_status urlCallback 2');
          this.redirect();
        }

      }, error => {
        this.loading.closeFullLoading();
        console.log('error Encriptar  cod_status urlCallback 2');
        this.redirect();
        });
      } else {
        this.loading.closeFullLoading();
        console.log('error Encriptar');
        this.redirect();

      }
    } else {
      this.loading.closeFullLoading();
      console.log('error Encriptar code == undefined || code == null');
      this.redirect();
    }
  }

  retryError(codStatus: string, titulo? : string,  detalle? : string, btn? : string) {
    if (codStatus == undefined || codStatus == null) codStatus = "20";
    if (!titulo) titulo = "Error";
    if (!detalle)  detalle = "No es posible realizar tu solicitud.";
    if (!btn) btn = "FINALIZAR";

    const modalObservable = this.showError(TypeModal.WARN, titulo, detalle, [btn]);

    if (modalObservable instanceof Observable) {
      modalObservable.subscribe(
        modalRes => {
          console.log(modalRes);
          if (modalRes.first) this.getDecryptEncryptUrlCallback(codStatus);
        },
        error => {
          console.error("error retryError");
        }
      );
    } else {
      console.error("this.modal.openModal no devolvió un Observable correctamente.");
    }

    //this.redirectTresSeg(codStatus);
 }



 cliccBotonCaptchaInvisible() {
  //lama a la funcion resolvedCaptchaInvisible
  setTimeout(() => {
    document.getElementById("botonCaptchaInvisible")?.click();
  }, 0);
}


  convertirStringBase64(data: string){
    let b64 = btoa(unescape(encodeURIComponent(data)));
    let txt = decodeURIComponent(escape(window.atob(b64)));
   // console.log('convertir base64:',b64);
  //  console.log('convertir string:',txt);
  //  console.log('----------------');
    return b64;
  }

  redirect(){
    this.loading.showFullLoading();
    if(this.storage.getItem('urlCallback') !== undefined || this.storage.getItem('urlCallback') !== null){
      this.redireccionService.actualizarURL(this.decryp(this.storage.getItem('urlCallback')),this.encryp('20'));
    }
  }

  decryp(a){
    return  new EncryptHelper(this.platformLocation).decryptAESHex(a);
  }

  encryp(a){
    return  new EncryptHelper(this.platformLocation).encryptAESHex(a);
  }

  /*
  redirectTresSeg(codStatus){
    setTimeout(() => {
      this.getDecryptEncryptUrlCallback(codStatus);
    }, 3000);
  }
*/

onKeyDown(event: KeyboardEvent) {
  // Lógica para manejar el evento keydown
 // console.log('Key Down:', event);
}

}
