import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken: string = this.storageService.getItem(
      environment.token + '-access_token'
    );

    // Remove sensitive headers
    let headers = request.headers
      .delete('X-Forwarded-For')
      .delete('X-Client-IP')
      .delete('X-Forwarded-IP')
      .delete('X-Forwarder-IP')
      .delete('X-Powered-By')
      .delete('X-AspNet-Version')
      .delete('X-AspNetMvc-Version')
      .delete('Server')
      .delete('Origin');

    if (accessToken !== null && accessToken !== '') {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    request = request.clone({ headers });

    return next.handle(request);
  }
}
