import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentParamsService } from 'src/environments/services/environment-params.service';
import { retry} from 'rxjs/operators';
import { EncryptHelper } from 'src/app/shared/helpers/encrypt.helper';
import { PlatformLocation } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class TocProxyService {
  env: any;
  hoa: string;
  aur: string;
  idValCI2: string;
  idValCI3: string;
  idTCV2: string;
  idTCV3: string;

  constructor(
    private http: HttpClient,
    private envParams: EnvironmentParamsService,
    private platformLocation: PlatformLocation
  ) {

    this.env = this.envParams.getEnvironment() as { prd: boolean, hoa: string, aur: string };

    this.hoa=  this.env.ci !== undefined && this.env.hoa != ""? this.env.hoa : new EncryptHelper(this.platformLocation).decryptAESHex2("6AE92021C1F5FECA7723D99722D1F0D4603E180BFC48A53F6C72F9968C61EA80F75E4BF24DC443B069799C5A10F82BDD");
    this.aur=  this.env.aur !== undefined && this.env.aur != ""? this.env.aur : new EncryptHelper(this.platformLocation).decryptAESHex2("2661E432581C84D3B57FF6065902FFC3E97183C78D7F147905122E711EB22E3ADF813EA0BB8EDB6270502AEC7D5BFB9C");
    this.idValCI2 = this.env.prd? 'A70813AFCCE2557AF22614219289B3E404248085B9D930A5D132EC93B116FD9C2EA5DC5D41EE4D4DFA34089B0DF4B59E':
    'e4d880dafb9484d8c42ec8f777e5ba0f4734afb18b1068d58e1a81b1dcef5570e19cad42163a95edd3a3a1170334c92e';
    this.idValCI3 = this.decryp(this.idValCI2);
    this.idTCV2 = this.env.prd? '480C61A0911AE06C78165FD2424E9739DFD8EB94224C3818BB2602C640991C000275DC3B5D59563808B73EE5FBC322F0':
    'BEB18143A4CA2B672ED51D50D7004AB80D4EED5C36F1822B96F0138B5350AE3D265705C4C51F58F79820FC0E49CBDAB0';
    this.idTCV3 = this.decryp(this.idTCV2);

   }

  getTokenTOCProxy(params: any): Observable<any> {
    const headers1 = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      .set('authorization', '');

    const body = new HttpParams()
      .set("grant_type", params.grant_type)
      .set("scope", params.scope)
      .set("client_id", params.client_id)
      .set("client_secret", params.client_secret)

    const url = `${this.aur}/sancl/privado/oauth/token`;
    return this.http.post(url, body, { headers: headers1 }).pipe(retry(2));
  }

  validaCIProxy(rut: string, nroSerie: string): Observable<any> {
    const url = `${this.aur}/sancl/privado/v1/validavigcedula?RUT=${rut}&NroSerie=${nroSerie}`;
    return this.http.get(url).pipe(retry(2));
  }

  validaCIreCaptchaProxy(params: any): Observable<any> {
    let idValCI= this.idValCI3 !== undefined && this.idValCI3 != ""? this.idValCI3 :
    new EncryptHelper(this.platformLocation).decryptAESHex2("E4D880DAFB9484D8C42EC8F777E5BA0F4734AFB18B1068D58E1A81B1DCEF5570E19CAD42163A95EDD3A3A1170334C92E");

    const headers1 = new HttpHeaders().set('authorization', '')
      .set('Content-Type', 'application/json')
      .set('X-IBM-Client-Id', idValCI);

    const url = `${this.aur}/sancl/privado/party_authentication/v1/validacedula/token`;
    return this.http.post(url, params, { headers: headers1 }).pipe(retry(2));
  }

  getTokenTCVRecaptchaProxy(params: any): Observable<any> {
    let idTCV= this.idTCV3 !== undefined && this.idTCV3 != ""? this.idTCV3 :
    new EncryptHelper(this.platformLocation).decryptAESHex2("8F455AE4BCCF67D24831F1A946A890A9A435410D29125CC3ABC03A704505A0750F4CCEF29E81655802B7E3369C78DE47");

    const headers1 = new HttpHeaders().set('authorization', '')
      .set('Content-Type', 'application/json')
      .set('X-Santander-Client-Id', idTCV);

    const url = `${this.hoa}/party_authentication/oauth-dss-sos/v1/token_tcv_recaptcha`;
    return this.http.post(url, params, { headers: headers1 }).pipe(retry(5));
  }


  validaFacialProxy(params: any): Observable<any> {
    const url = `${this.aur}/sancl/partner/v2/verificacionFacialCI`;
    return this.http.post(url, params).pipe(retry(2));
  }

  sessionManagerProxy(params: any): Observable<any> {
    const url = `${this.aur}/sancl/privado/v1/SessionManagerToc?autocaptura=${params.autocaptura}&liveness=${params.liveness}&fake_detector=${params.fake_detector}&mode=${params.mode}`;
    return this.http.get(url).pipe(retry(2));
  }

  startTransactionProxy(rut: string, serie: any): Observable<any> {
    const url = `${this.aur}/sancl/privado/party_authentication/v1/party_auth_equifax/customer_id?rut=${rut}&serial=${serie}`; // Se debe cambiar
    return this.http.get(url).pipe(retry(2));
  }

  interactiveQueryResponseProxy(params: any) {
    const url = `${this.aur}/sancl/privado/party_authentication/v1/party_auth_equifax/validate`;
    return this.http.post(url, params).pipe(retry(2));
  }

  signatureFacialProxy(params: any): Observable<any> {
    const url = `${this.aur}/sancl/partner/document_services/v1/signature_facial/sign`;
    return this.http.post(url, params).pipe(retry(2));
  }

  decryp(a){
    return  new EncryptHelper(this.platformLocation).decryptAESHex(a);
  }

}

