import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

  private reloadCounterKey = 'reloadCounter';

    constructor() { }

    setItem(token: string, value: object | string) {
        if (value instanceof Object) {
            localStorage.setItem(token, JSON.stringify(value));
            Object.keys(value).forEach(key => {
                let valueToStore = value[key];
                if (valueToStore instanceof Object) {
                    valueToStore = JSON.stringify(valueToStore);
                }
                localStorage.setItem(`${token}-${key}`, valueToStore);
            });
        } else {
            localStorage.setItem(token, value);
        }
    }

    getItem(key: string): any {
        return localStorage.getItem(key);
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    removeAll() {
        localStorage.clear();
    }

    incrementReloadCounter(): number {
      let currentCount = parseInt(localStorage.getItem(this.reloadCounterKey) || '0');
      currentCount++;
      localStorage.setItem(this.reloadCounterKey, currentCount.toString());
      return currentCount;
    }

    resetearReloadCounter(): number {
      let currentCount = parseInt( '0');
      localStorage.setItem(this.reloadCounterKey, currentCount.toString());
      return currentCount;
    }

}
