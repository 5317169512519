import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingModule } from '@santander/loading';
import { TealiumModule } from '@santander/tealium';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    LoadingModule
  ],
  exports: [
    FlexLayoutModule,
    TealiumModule
  ]
})
export class CoreModule { }
